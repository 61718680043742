import '../App.css';
import NotFound from '../pages/NotFound'
import SetLang from './SetLang'
import React from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
//Pages import
import HomeContainer from '../pages/Home/HomeContainer'
import MyAccountContainer from '../pages/MyAccount/MyAccountContainer'

const App = () => {
		
	if(!localStorage.getItem('lang'))
		localStorage.setItem('lang', 'es');
	return(
	<Router>
				<Switch>
					{/* PUBLIC */}
						{/* <Route path="/" component ={localStorage.getItem('lang') !== null  && (localStorage.getItem('lang') === "/es" || localStorage.getItem('lang') === "/en") ? <Navigate to={"/" + localStorage.getItem('lang')} /> : "/es"} /> */}
						<Route exact path="/" render={(props) => <HomeContainer {...props} myAccountMenu={false}/>}>
							<Redirect to={"/" + (localStorage.getItem('lang') !== null ? localStorage.getItem('lang')  : 'es')}/> 
						</Route>
						<Route exact path="/:lang/my-account" /* component={MyAccountContainer} */ render={(props) => <MyAccountContainer {...props} myAccountMenu={true} />}/>
						<Route exact path="/:lang" /* component={HomeContainer} */ render={(props) => <HomeContainer {...props} myAccountMenu={false}/>}/>
						<Route exact path="/:lang/change-language" component={SetLang}/>
						<Route component={NotFound}/>
					{/* PUBLIC */}          
				</Switch>
	</Router>

  

)
}


export default App;
