import React from 'react'
import {Link} from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';
import lang from '../../components/languages/lang';
import LocalizedStrings from 'localized-strings';
import customInterfaceFunction from '../../components/languages/customInterfaceFunction';
import base_url from '../../base_url'
import base_url_assets from '../../base_url_assets';

const Footer = ({myAccountMenu, goTop}) => {
    let language = new LocalizedStrings(
        lang,
        {
            customLanguageInterface: customInterfaceFunction
        }
        );
    let file = "/downloads/privacy-policy-EN.docx";     
    let file2 = "/downloads/terms-conditions-EN.docx";     
    if(localStorage.getItem("lang") === "en"){
        file = "/downloads/privacy-policy-EN.docx";
        file2 = "/downloads/terms-conditions-EN.docx";
    } 
    return(<>
    <footer className="footer">
                    
                        <div className="footer-top">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-12">
                                        
                                        <div className="single-footer f-about">
                                            <div className="logo">
                                                <Link to="#home">
                                                    <img src={base_url_assets+"/assets/images/logo/white1.png"} alt="#"/>
                                                </Link>
                                            </div>
                                            <p>{language.footer1}</p>
                                            <ul className="social">
                                                <li><Link to=""><i className="lni lni-facebook-filled"></i></Link></li>
                                                <li><Link to=""><i className="lni lni-twitter-original"></i></Link></li>
                                                <li><Link to=""><i className="lni lni-instagram"></i></Link></li>
                                                {/* <li><Link to="javascript:void(0)"><i className="lni lni-linkedin-original"></i></Link></li>
                                                <li><Link to="javascript:void(0)"><i className="lni lni-youtube"></i></Link></li>
                                                <li><Link to="javascript:void(0)"><i className="lni lni-pinterest"></i></Link></li> */}
                                            </ul>
                                            <p className="copyright-text">{language.footer2} <a href="https://www.diazapps.com/"
                                                    rel="noreferrer" target="_blank">DiazApps</a>
                                            </p>
                                        </div>
                                    
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-12">
                                        <div className="row">
                                            {/* <div className="col-lg-3 col-md-6 col-12">
                                            
                                                <div className="single-footer f-link">
                                                    <h3>Solutions</h3>
                                                    <ul>
                                                        <li><Link to="javascript:void(0)">Marketing</Link></li>
                                                        <li><Link to="javascript:void(0)">Analytics</Link></li>
                                                        <li><Link to="javascript:void(0)">Commerce</Link></li>
                                                        <li><Link to="javascript:void(0)">Insights</Link></li>
                                                        <li><Link to="javascript:void(0)">Promotion</Link></li>
                                                    </ul>
                                                </div>
                                            
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-12">
                                            
                                                <div className="single-footer f-link">
                                                    <h3>Support</h3>
                                                    <ul>
                                                        <li><Link to="javascript:void(0)">Pricing</Link></li>
                                                        <li><Link to="javascript:void(0)">Documentation</Link></li>
                                                        <li><Link to="javascript:void(0)">Guides</Link></li>
                                                        <li><Link to="javascript:void(0)">API Status</Link></li>
                                                        <li><Link to="javascript:void(0)">Live Support</Link></li>
                                                    </ul>
                                                </div>
                                            
                                            </div> */}
                                            <div className="offset-lg-6 offset-md-9 col-lg-3 col-md-6 col-12">
                                            
                                                <div className="single-footer f-link">
                                                    <h3>{language.footer3}</h3>
                                                    <ul>
                                                        <li><HashLink to="#features">{language.features}</HashLink></li>
                                                        <li><HashLink to="#pricing">{language.package}</HashLink></li>
                                                        <li><HashLink to="#team">{language.team}</HashLink></li>
                                                        <li><HashLink to="#whitepaper">{language.whitepaper}</HashLink></li>
                                                        
                                                    </ul>
                                                </div>
                                            
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-12">
                                                
                                                <div className="single-footer f-link">
                                                    <h3>{language.footer4}</h3>
                                                    <ul>
                                                        <li><Link to={file2} target="_blank" download>{language.footer5}</Link></li>
                                                        <li><Link to={file} target="_blank" download>{language.footer6}</Link></li>
                                                    </ul>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
        </footer>
        <HashLink  to={myAccountMenu ? base_url + "/my-account" : "#home"} className="scroll-top" onClick={myAccountMenu ? goTop : null}>
            <i className="lni lni-chevron-up"></i>
        </HashLink>
    </>)
        

}

export default Footer        
        