import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import lang from '../../components/languages/lang'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import LocalizedStrings from 'localized-strings';
import customInterfaceFunction from '../../components/languages/customInterfaceFunction';

const ModalMint = ({handleClose, show, handleSubmit, handleChange, state, showMiabc, handleMiabc, handleChangeCountry, handleChangeRegion}) => {
    let language = new LocalizedStrings(
        lang,
        {
            customLanguageInterface: customInterfaceFunction
        }
        ); 
                return (<Modal show={show==="mints"} key={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className="text-dark">{language.mintData}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <span className="mt-4 modal-title h5 d-flex justify-content-center">{language.nesherData}</span>
                        <hr className="mb-4"></hr>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>{language.email}</Form.Label>
                            <Form.Control value={state.email} name="email" type="email" placeholder="email" onChange={handleChange} required/>
                            <div id="emailHelp" className="form-text">{language.emailNote}</div>
                            </Form.Group>
                        </Row>
                        <span className="mt-5 modal-title h5 d-flex justify-content-center">{language.miabcData}</span>
                        <hr className="mb-4"></hr>
                        <Form.Group className="mb-3" id="formGridCheckbox">
                            <div className="d-flex justify-content-center form-check">
                                <input name="check" type="checkbox" className="form-check-input me-2" onClick={handleMiabc} onChange={handleChange} defaultChecked={state.miabc_benefits ? true : false}/>
                                <label title="" className="form-check-label">{language.formCheck}</label>
                            </div>
                            <div id="emailHelp" className="form-text d-flex justify-content-center">{language.formCheckNote}</div>
                            <hr className="mb-4"></hr>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="address">
                            <Form.Label>{language.address}</Form.Label>
                            <Form.Control value={state.mailing_address_1} name="mailing_address_1" placeholder={language.addressPlace} disabled = {showMiabc === true ? true : false} onChange={handleChange} required = {showMiabc === true ? false : true} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="address2">
                            <Form.Label>{language.address2}</Form.Label>
                            <Form.Control value={state.mailing_address_2} name="mailing_address_2" placeholder={language.address2Place} disabled = {showMiabc === true ? true : false} onChange={handleChange} required = {showMiabc === true ? false : true}/>
                        </Form.Group>

                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="country">
                            <Form.Label>{language.mailingCountry}</Form.Label>
                            <CountryDropdown name="country" disabled = {showMiabc === true ? true : false} value={state.mailing_country} onChange={handleChangeCountry} className="form-control" required = {showMiabc === true ? false : true}/>
                            </Form.Group>

                            <Form.Group as={Col} controlId="region">
                            <Form.Label>{language.mailingCity}</Form.Label>
                            <RegionDropdown name="region"  disabled = {showMiabc === true ? true : false} disableWhenEmpty={true} country={state.mailing_country} value={state.mailing_state} onChange={handleChangeRegion} className="form-control" required = {showMiabc === true ? false : true}/>
                            {/* <Form.Select defaultValue="Choose...">
                                <option>Choose...</option>
                                <option>...</option>
                            </Form.Select> */}
                            </Form.Group>

                            <Form.Group as={Col} controlId="code">
                            <Form.Label>{language.mailingZip}</Form.Label>
                            <Form.Control value={state.zip_code} name="zip_code" disabled = {showMiabc === true ? true : false} onChange={handleChange} required = {showMiabc === true ? false : true}/>
                            </Form.Group>
                        </Row>

                        
                        <div className="button add-list-button d-flex justify-content-center">
                            <Button variant="primary" className="btn" type="submit">
                                {language.mailingSubmit}
                            </Button>
                        </div>
                        
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {language.mailingClose}
                </Button>
                {/* <Button variant="primary" onClick={handleClose}>
                    Save Changes
                </Button> */}
                </Modal.Footer>
            </Modal>)
}

export default ModalMint