import React, { useState, useEffect } from "react";
import lang from './languages/lang';
import LocalizedStrings from 'localized-strings';
import customInterfaceFunction from './languages/customInterfaceFunction';
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/es';
import moment from 'moment';

const MyContests = ({mints, handleShowContests, start}) => {

    let language = new LocalizedStrings(
        lang,
        {
            customLanguageInterface: customInterfaceFunction
        }
    );
    const [timeNow, setTimeNow] = useState();
    const [int, setInt] = useState();
    useEffect(() => {
        setInt(setInterval(() => setTimeNow(moment.utc()), 1000));
        return () => {
            clearInterval(int);
        }  
    }, [])

    let myContests = <div className="text-center container overflow-hidden mt-3 py-4">{language.noContestData}</div>
        if(mints.length > 0){
            myContests = mints.map((p,idx) => {
            start = start + 1;
            return (<div key = {idx} className="container overflow-hidden mt-3 py-4">
                        <div className="my-2 mx-4 bordershadow row g-2 border bg-light">
                            <h3 className="text-center">{language.contestPass} #{p.id}</h3>
                            <span className="text-center mb-2 text-dark fw-bold">{language.beneficiary} <span className="text-primary fst-italic">{p.email}</span></span>
                            {p.contests.map((m,index) => {
                                let after = false;
                                let action = language.upload_files;
                                let classFillform = "button add-list-button mb-4";
                                let classFillform2 = "btn";   
                                let currentUTC = timeNow ? timeNow : moment.utc();
                                let end = moment.utc(m.end_date);
                                let start =  moment.utc(m.start_date);
                                let active = "";
                                let dates = <>
                                            <div>{language.start} <Moment format="LLLL" locale={localStorage.getItem('lang') === 'es' ? 'es' : 'en'}>{m.start_date}</Moment> UTC</div>
                                            <div className="mb-4">{language.end} <Moment format="LLLL" locale={localStorage.getItem('lang') === 'es' ? 'es' : 'en'}>{m.end_date}</Moment> UTC</div>
                                            </> 
                                        
                                if(currentUTC >= start && currentUTC <= end){
                                 
                                    let diff =  end.diff(currentUTC);
                                    let duration = moment.duration(diff, "milliseconds");
                                    dates = <>  <span className="me-1">{language.countText}</span>
                                                <span className="text-danger">{duration.days() > 0 ? duration.days() + "d" : ""} {duration.hours() > 0 ? duration.hours() + "h" : ""} {duration.minutes() > 0 ? duration.minutes() + "m" : ""} {duration.seconds()}s </span>
                                            </>
                                    active = <div className="badge bg-green">{language.activeCourse}</div>
                                            
                                }

                                let place = "";
                                if(m.pivot.place === 1){
                                    place = language.first;
                                }
                                if(m.pivot.place === 2){
                                    place = language.second;
                                }
                                if(m.pivot.place === 3){
                                    place = language.third;
                                }
                                
                                let result = m.pivot.place ? <span className="text-green zoom-in-zoom-out">{language.winText} {place}</span>: language.noWinText;

                                
                                if(currentUTC > end){
                                    after = true
                                    dates = <>  <p className="text-danger me-1">{language.finishedText}</p>
                                                <p className="mb-4">{result}</p>
                                            </>                                    
                                }
                                if(currentUTC < start){
                                    after = true                                   
                                }
                                if(m.pivot.email){                                     
                                    action = language.edit_files;
                                    classFillform = "button1 mb-4";
                                    classFillform2 = "btn1";
                                }
                                return(<div key = {index} className="col-6 text-center my-0 mx-0 border">
                                            <div className="mt-2 fst-italic text-dark fw-bold fs-6 mb-1">{m.description}</div> <div className="mb-4">{active}</div>
                                            <div className={classFillform}>                                                
                                                <button disabled={after ? true : false} onClick={() => handleShowContests(m.pivot.id,"contest")} type="button" className={classFillform2}>{action}</button>
                                            </div>
                                            <div>{dates}</div>
                                    </div>)
                            })}
                                                
                        </div>
                    </div>) 

            });
        } 
    
   return(myContests) 
}

export default MyContests