import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import lang from '../../components/languages/lang'
import LocalizedStrings from 'localized-strings';
import customInterfaceFunction from '../../components/languages/customInterfaceFunction';
import public_url from '../public_images';

const ModalContest = ({handleCloseContests, showContests, handleSubmitContests, handleChangeContests, stateContest,onSelectFileMultimedia, multimediaRef, certificateRef, onSelectFile}) => {
    let language = new LocalizedStrings(
        lang,
        {
            customLanguageInterface: customInterfaceFunction
        }
        ); 
        let certExt = stateContest.certificate_path ? stateContest.certificate_path.split('.').pop() : "";
        let multExt = stateContest.multimedia_path ? stateContest.multimedia_path.split('.').pop() : "";
        let current = "";
        let currentMult = ""; 
        let certImage = "";
        let multImage = "";
        if(certExt === "pdf"){
            certImage = <embed className="mx-4 my-2 mt-3" src={public_url + "/files/certificates/"+ stateContest.certificate_path} height="250"/>
        }else{
            certImage = <img src={public_url + "/files/certificates/"+ stateContest.certificate_path} alt="" height="300" className="img-thumbnail-2"></img>
            
        }
        if(multExt === "mpg" || multExt === "mp4" || multExt === "quicktime" || multExt === "MOV" || multExt === "avi"){
            multImage = <video className="" controls height="300">
                            <source src={public_url + "/files/multimedia/"+ stateContest.multimedia_path}/>
                        </video>
                                   
        }
        if(multExt === "mp3" || multExt === "mpeg" || multExt === "ogg" ){
            multImage = <audio className="" controls>
                            <source src={public_url + "/files/multimedia/"+ stateContest.multimedia_path}/>
                        </audio>
                                   
        }
        if(multExt === "jpg" || multExt === "jpeg" || multExt === "png" ){
            multImage = <img src={public_url + "/files/multimedia/"+ stateContest.multimedia_path} alt="" height="300" className="img-thumbnail-2"></img>
                                   
        }
        if(stateContest.certificate_path && stateContest.multimedia_path){
            current =  <div>
                           <div className="text-center text-orange">
                               <i className="lni lni-eye"></i><span className="ms-1 mb-3">{language.current}</span>
                           </div>
                           <div className="d-flex justify-content-center mb-4">{certImage}</div>
                       </div> 
            currentMult =   <div>
                               <div className="text-center text-orange">
                                   <i className="lni lni-eye"></i><span className="ms-1 mb-3">{language.current}</span>
                               </div>
                               <div className="d-flex justify-content-center mb-4">{multImage}</div>
                           </div> 
       }
                return (<Modal show={showContests==="contest"} onHide={handleCloseContests} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="text-dark">{language.contestPass} <i>{stateContest.course_desc}</i></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmitContests}>
                            <span className="mt-4 modal-title h5 d-flex justify-content-center">{language.contestData}</span>
                            <hr className="mb-4"></hr>
                            <Row className="mb-3">
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>{language.name}</Form.Label>
                                    <Form.Control value={stateContest.beneficiary_name} name="beneficiary_name" placeholder={language.name} onChange={handleChangeContests} required/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>{language.emailPart}</Form.Label>
                                    <Form.Control value={stateContest.beneficiary_email} name="beneficiary_email" type="email" placeholder={language.emailPart} onChange={handleChangeContests} required/>
                                </Form.Group>
                            </Row>
                            <span className="mt-4 modal-title h5 d-flex justify-content-center">{language.filesData}</span>
                            <hr className="mb-4"></hr>
                            <Form.Group controlId="formFile" className="mb-3">
                                <Form.Label>{language.certificateDesc}</Form.Label>
                                <Form.Control type="file" ref={certificateRef} onChange={onSelectFile} accept="image/*,application/pdf"/>
                            </Form.Group>
                            {current}
                            <Form.Group controlId="formFile2" className="mb-3">
                                <Form.Label>{stateContest.course_desc === '"Aprende a bailar K-pop"' ? language.multimediaDesc2 : language.multimediaDesc}</Form.Label>
                                <Form.Control type="file" ref={multimediaRef} accept={stateContest.course_desc === '"Aprende a bailar K-pop"' ? "video/*" : "image/*"} onChange={onSelectFileMultimedia}/>
                            </Form.Group>
                            {currentMult}
                            <div className="button add-list-button d-flex justify-content-center mt-5">
                                <Button variant="primary" className="btn" type="submit">
                                    {language.filesSubmit}
                                </Button>
                            </div>
                            
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseContests}>
                        {language.mailingClose}
                    </Button>
                    </Modal.Footer>
                </Modal>)
}

export default ModalContest