import React from 'react';
import Menu from '../Menu/MenuContainer';
import Footer from '../Footer/FooterContainer';
import lang from '../../components/languages/lang';
import LocalizedStrings from 'localized-strings';
import customInterfaceFunction from '../../components/languages/customInterfaceFunction';
import MyMints from '../../components/MyMints';
import MyContests from '../../components/MyContests';
import AdminSection from '../../components/AdminSection'
import Pagination from "react-js-pagination";
import ModalContest from '../../components/modals/ModalContest';
import ModalMint from '../../components/modals/ModalMint';
import ContestEntries from '../../components/ContestEntries';

const MyAccount = ({handleShow, handleShowContests, handleClose, handleCloseContests, show, showContests, handleSubmit, handleSubmitContests, handleChange, handleChangeContests, handleChangeRegion, handleChangeCountry, handleSubmitAdmin, state, stateContest, myAccountMenu, nfts, mints, mintsContests, handleMiabc, showMiabc, handlePageChange, handlePageChangeContests, activePage, mintPart, mintPartContests, start, startContests, handleStart, tabButton, tabButton1, onSelectFile, onSelectFileMultimedia, multimediaRef, certificateRef, isOwner, handleChangeAdmin, stateAdmin, entries, handleGetEntries, noEntries}) => {
    
    let language = new LocalizedStrings(
        lang,
        {
            customLanguageInterface: customInterfaceFunction
        }
        ); 
        
        let length = nfts.length <= 1 ? "col-lg-5 col-md-6 col-12 image ms-5 me-3 px-0 mt-5 bordershadow" : "col-lg-3 col-md-6 col-12 image ms-5 me-3 px-0 mt-5 bordershadow"
        let lengthTitle = nfts.length <=1 ? "text-end text-orange" : "text-center text-orange";
        let lengthTitleMints = mints.length <=1 ? "text-end text-orange" : "text-center text-orange";
        let lengthTitleMintsContests = mintsContests.length <=1 ? "text-center text-orange" : "text-center text-orange";
        const attrib = (val,idx) => {
            let frameColor = "text-orange";
            let res = "";

            if(val.trait_type=== "Framework"){
                let value = ""
                if(val.value==="Wood"){
                    frameColor = "wood";
                    value = language.frameWood;

                }
                if(val.value==="Gold"){
                    frameColor = "gold";
                    value = language.frameGold;

                }
                if(val.value==="Silver"){
                    frameColor = "silver";
                    value = language.frameSilver;

                }
                if(val.value==="Diamond"){
                    frameColor = "diamond";
                    value = language.frameDiamond;

                }
                if(val.value==="Emerald"){
                    frameColor = "emerald";
                    value = language.frameEmerald;

                }
                res = <li key={idx}>{language.charlyDesc} <br></br><span className={frameColor}>{value}</span><br></br>{localStorage.getItem("lang") === "es" ? "" : " "+ language.charlyDesc2}</li>;   
            }else 
            if(val.trait_type==="Face"){
                let value = ""
                if(val.value==="Sad"){
                    value = language.sad;
                }
                if(val.value==="Happy"){
                    value = language.happy;
                }
                if(val.value==="Angry"){
                    value = language.angry;
                }
                if(val.value==="Surprise"){
                    value = language.surprise;
                }
                if(val.value==="Wink"){
                    value = language.friendly;
                }
                res = <li key={idx}>{language.charlyDesc3} <span className="">{value}</span></li>;   
            }
            return res;
        }
    let myNfts = nfts.map((p,index) => {
        return (
                <div key={index} className={length}>
                    <div className="single-blog wow fadeInUp" data-wow-delay=".2s"> 
                        <div className="blog-img" id="nft-images">
                            <img src={p.image} alt="#" height="auto" width={500}/>
                        </div>
                        <div className="blog-content text-center mb-2 mt-2">
                            <h5>
                            {p.name}
                            </h5>
                            <ul>
                                {p.attributes.map((a,idx) => attrib(a,idx))}
                            </ul>                                 
                        </div>
                            
                    </div>
                </div>
                
                )
    });
    let pagination =<div className="d-flex justify-content-center mt-2">
                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={6}
                            totalItemsCount={mints.length}
                            pageRangeDisplayed={4}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div>
    let pagination1 =<div className="d-flex justify-content-center mt-2">
                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={1}
                            totalItemsCount={mintsContests.length}
                            pageRangeDisplayed={10}
                            onChange={handlePageChangeContests}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div>
    let buttonWallet = <p className="text-white">{language.walletConnect}</p>
    console.log(stateContest.course_desc)
    let admin = isOwner ? <button className="nav-link" id="v-pills-admin-tab" data-bs-toggle="pill" data-bs-target="#v-pills-admin" type="button" role="tab" aria-controls="v-pills-admin" aria-selected="false">{language.admin}</button> : "";
    let contestEntries = isOwner ? <button onClick={handleGetEntries} className="nav-link" id="v-pills-admin-tab" data-bs-toggle="pill" data-bs-target="#v-pills-entries" type="button" role="tab" aria-controls="v-pills-entries" aria-selected="false">{language.entries}</button> : "";
    return(
        <>  <Menu myAccountMenu = {myAccountMenu}/>

            <ModalMint handleClose={handleClose} show={show} handleSubmit={handleSubmit} handleChange={handleChange} state={state} handleMiabc={handleMiabc} handleChangeRegion={handleChangeRegion} handleChangeCountry={handleChangeCountry} showMiabc={showMiabc}/>
                    
            <ModalContest handleCloseContests={handleCloseContests} showContests={showContests} handleSubmitContests={handleSubmitContests} handleChangeContests={handleChangeContests} stateContest={stateContest}       onSelectFileMultimedia={onSelectFileMultimedia} onSelectFile={onSelectFile} multimediaRef={multimediaRef} certificateRef={certificateRef}/>

            <div className="breadcrumbs">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 col-md-12 col-12">
                            <div className="breadcrumbs-content">
                                <h1 className="page-title">{language.myAccount}</h1>
                            </div>
                            <ul className="breadcrumb-nav">
                                <li>{localStorage.getItem("currentAccount") ? localStorage.getItem("currentAccount") : buttonWallet}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
            <section className="section blog-single">
                <div className="container">
                    <div className="row">
                            <div className="d-flex align-items-start">
                                <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <button ref={tabButton} onClick={handleStart} className="nav-link active" id="v-pills-data-tab" data-bs-toggle="pill" data-bs-target="#v-pills-data" type="button" role="tab" aria-controls="v-pills-data" aria-selected="true">{language.mintData}</button>
                                    <button className="nav-link" id="v-pills-nft-tab" data-bs-toggle="pill" data-bs-target="#v-pills-nft" type="button" role="tab" aria-controls="v-pills-nft" aria-selected="false">{language.myNfts}</button>
                                    <button ref={tabButton1} onClick={handleStart} className="nav-link" id="v-pills-contest-tab" data-bs-toggle="pill" data-bs-target="#v-pills-contest" type="button" role="tab" aria-controls="v-pills-contest" aria-selected="false">{language.contests}</button>
                                    {admin}
                                    {contestEntries}
                                </div>
                                <div className="tab-content" id="v-pills-tabContent">
                                    <div className="tab-pane fade show active" id="v-pills-data" role="tabpanel" aria-labelledby="v-pills-data-tab">
                                        <div className="row">
                                            <h5 className={lengthTitleMints}>{language.myMints}</h5>
                                            <MyMints mints={mintPart} start={start} handleShow={handleShow}/>
                                            
                                        </div>
                                        {pagination}
                                        
                                    </div>
                                    
                                    <div className="tab-pane fade" id="v-pills-nft" role="tabpanel" aria-labelledby="v-pills-nft-tab">
                                        <div className="row">
                                            <h5 className={lengthTitle}>{language.myNfts}</h5>{myNfts}
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-contest" role="tabpanel" aria-labelledby="v-pills-contest-tab">

                                        <div className="row">
                                            <h5 className={lengthTitleMintsContests}>{language.contests}</h5>
                                            <MyContests key={showContests} mints={mintPartContests} start={startContests} handleSubmitContests={handleSubmitContests} handleShowContests={handleShowContests} handleCloseContests={handleCloseContests} showContests={showContests} stateContest={stateContest}  handleChangeContests={handleChangeContests}/>
                                            
                                        </div>
                                        {pagination1}

                                    </div>
                                    <div className="tab-pane fade" id="v-pills-admin" role="tabpanel" aria-labelledby="v-pills-admin-tab">
                                        <div className="row">
                                            <h5 className="text-center text-orange mb-4">{language.admin}</h5>
                                            <AdminSection handleChangeAdmin={handleChangeAdmin} stateAdmin={stateAdmin} handleSubmitAdmin = {handleSubmitAdmin}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-entries" role="tabpanel" aria-labelledby="v-pills-entries-tab">
                                        <div className="row">
                                            <h5 className="text-center text-orange mb-4">{language.entries}</h5>
                                            <ContestEntries entries={entries} noEntries={noEntries}/>
                                        </div>
                                    </div>
                            </div>
                        </div>            
                    </div>
                </div>
            </section>
            <Footer myAccountMenu = {myAccountMenu}/>       
    </>
   
    )

}

export default MyAccount