import React from 'react'
import Footer from './Footer'

const FooterContainer = ({myAccountMenu}) => {

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
    }

    return <Footer myAccountMenu={myAccountMenu}
                   goTop = {scrollToTop}                
    />

}

export default FooterContainer