/* const base_url = 'http://diazapps.com/public_nesher/api' */
let base = "/es"
/* let base = "/staging.nesheracademy.com/es" */

if(localStorage.getItem('lang')){
    
     base = "/" + localStorage.getItem('lang')

}

export default base