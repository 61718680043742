import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import lang from '../components/languages/lang';
import LocalizedStrings from 'localized-strings';
import customInterfaceFunction from '../components/languages/customInterfaceFunction';

const AdminSection = ({handleSubmitAdmin, handleChangeAdmin, stateAdmin, ownerAddress}) => {
    let language = new LocalizedStrings(
        lang,
        {
            customLanguageInterface: customInterfaceFunction
        }
        ); 
                return (
                    
                        <Form onSubmit={handleSubmitAdmin} className="row ms-4">
                            {/* <span className="mt-4 modal-title h5 d-flex justify-content-center">{language.admin}</span> */}
                            {/* <span className="mt-4 modal-title h5 d-flex justify-content-center">Owner Address: {ownerAddress}</span> */}
                            <Form.Group as={Col} controlId="formGridUrl">
                                <Form.Label>{language.setUrl}</Form.Label>
                                <Form.Control value={stateAdmin.url} name="url" type="text" placeholder={language.setUrl} onChange={handleChangeAdmin} />
                            </Form.Group>                        
                            <hr className="mb-4 mt-4"></hr>
                            <Form.Group as={Col} controlId="formGridPrice">
                                <Form.Label>{language.setPrice}</Form.Label>
                                <Form.Control value={stateAdmin.price} name="price" type="text" placeholder={language.setPrice} onChange={handleChangeAdmin} />
                            </Form.Group>                        
                            <hr className="mb-4 mt-4"></hr>
                            <Form.Group as={Col} controlId="formGridSupply">
                                <Form.Label>{language.setSupply}</Form.Label>
                                <Form.Control value={stateAdmin.supply} name="supply" type="text" placeholder={language.setSupply} onChange={handleChangeAdmin} />
                            </Form.Group>                        
                            <hr className="mb-4 mt-4"></hr>
                            <Form.Group as={Col} controlId="formGridMax">
                                <Form.Label>{language.setMaxMint}</Form.Label>
                                <Form.Control value={stateAdmin.max} name="max" type="text" placeholder={language.setMaxMint} onChange={handleChangeAdmin} />
                            </Form.Group>
                            
                            <div className="button add-list-button d-flex justify-content-center mt-4">
                                <Button variant="primary" className="btn" type="submit">
                                    {language.updateButton}
                                </Button>
                            </div>
                            
                        </Form>
                   
                    )
}

export default AdminSection