import React from "react";
import public_url_images from "./public_images";
import lang from '../components/languages/lang'
import LocalizedStrings from 'localized-strings';
import customInterfaceFunction from '../components/languages/customInterfaceFunction';

const ContestEntries = ({entries, noEntries}) =>{
    let language = new LocalizedStrings(
        lang,
        {
            customLanguageInterface: customInterfaceFunction
        }
        ); 
    let res = <div className="text-center container overflow-hidden mt-3 py-4">{noEntries}</div>;
    console.log(entries);
    if(entries.customer_mints && !entries.message){
        console.log(entries);
        let title = <h5 className="text-center mb-5">
                        <hr className="ms-5"></hr>
                            <i>{entries.description}</i>
                        <hr className="ms-5"></hr>
                    </h5>
                    
        res = entries.customer_mints.map((e,index) => {
            let certImage = "";
            let multImage = "";
            let certExt = e.pivot.certificate_path ? e.pivot.certificate_path.split('.').pop() : "";
            let multExt = e.pivot.multimedia_path ? e.pivot.multimedia_path.split('.').pop() : "";
            if(certExt === "pdf"){
                certImage = <embed className="mx-4 my-2 mt-3" src={public_url_images + "/files/certificates/"+ e.pivot.certificate_path} height="auto" width={400}/>
            }else{
                certImage = <img src={public_url_images + "/files/certificates/"+ e.pivot.certificate_path} alt="" height="auto" width={400} className="img-thumbnail-2"></img>
                
            }
            if(multExt === "mpg" || multExt === "mp4" || multExt === "quicktime" || multExt === "MOV" || multExt === "avi"){
                multImage = <video className="" controls height="400" width={400}>
                                <source src={public_url_images + "/files/multimedia/"+ e.pivot.multimedia_path}/>
                            </video>
                                    
            }
            if(multExt === "mp3" || multExt === "mpeg" || multExt === "ogg" ){
                multImage = <audio className="" controls height="auto" width={400}>
                                <source src={public_url_images + "/files/multimedia/"+ e.pivot.multimedia_path}/>
                            </audio>
                                    
            }
            if(multExt === "jpg" || multExt === "jpeg" || multExt === "png" ){
                multImage = <img src={public_url_images + "/files/multimedia/"+ e.pivot.multimedia_path} alt="" height="auto" width={400} className="img-thumbnail-2"></img>
                                    
            }

            let content = e.pivot.email  ? <div><div className="blog-img d-flex justify-content-center bordershadow py-3" id="nft-images">
            {certImage}
            {multImage}
        </div>
        <div className="blog-content text-center mb-2 mt-2">
            
            <ul className="mt-4">
                <li><strong>{language.name}</strong> : {e.pivot.name}</li>
                <li><strong>{language.emailPart}</strong> : {e.pivot.email}</li>
                {/* <li><strong>{language.name}<</strong> : </li> */}
            </ul>                                 
        </div> </div>: <div className="text-center bordershadow">{language.noParticipate}</div>
            return (
                    <div key={index} className={"col-lg-12 col-md-12 col-12 mx-4 mb-5 mt-4"}>
                        <div className="single-blog wow fadeInUp" data-wow-delay=".2s"> 
                            <h5 className="text-center mb-5">
                                {e.email}
                            </h5>
                            
                            {content}
                                
                      </div>
                    </div>
                    
                    )
        }); 
    res = <div>{title}{res}</div>     
    }    
    return(res);

}

export default ContestEntries