import {useHistory, useParams } from 'react-router-dom'

const SetLang = ({props}) => {
    
    let { lang } = useParams();
    console.log(lang)
    let history = useHistory();
    localStorage.setItem('lang', lang);
    history.push('/' + lang);
    window.location.reload();

}
export default SetLang