import React, { useState, useEffect } from 'react'
/* import Loading from '../../components/Loading' */
import Home from './Home'
import base_url from '../../base_url'
import api_base_url from '../../components/config'
import Loading from '../../components/Loading'
import { ethers } from 'ethers';
import { ContractABI } from "../../contracts/Abi";
import { contractAddress } from "../../contracts/Contract";
import Swal from 'sweetalert2'
import langFile from '../../components/languages/lang'
import LocalizedStrings from 'localized-strings';
import customInterfaceFunction from '../../components/languages/customInterfaceFunction';
import 'moment-timezone';
import 'moment/locale/es';
import moment from 'moment';

const HomeContainer = (props) => {
   //Código tentativo para crear código desde la página en firebase
        /* const createCode = firebase.database().ref("accesscodes/");
        createCode.push({
            code: "12345",
            value: "false",
          
        }); */
    const [messageAddress, setMessageAddress] = useState("");
    const [messageHash, setMessageHash] = useState("");
    const [state, setState] = useState({
      mailing_address_1: "",
      mailing_address_2: "",
      mailing_name: "",
      mailing_country: "",
      mailing_state: "",
      mailing_city: "",
      zip_code: "",
      email: "",
      phone: "",
      miabc_benefits: true,
      wallet_address:"",
    });
    const [stateMessage, setStateMessage] = useState({
      mailing_address_1: "",
      mailing_address_2: "",
      mailing_name: "",
      mailing_country: "",
      mailing_state: "",
      mailing_city: "",
      zip_code: "",
      email: "",
      phone: "",
      miabc_benefits: "",
      wallet_address:"",
    });
    const [lang, SetLang] = useState("/es");
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const cant_mint = 1;
    const notFunds = -32000;
    const notNfts = 3;
    const rejected = 4001;
    const alreadyRequested = -32002;
    const { ethereum } = window;

    var message = ''
    if(stateMessage.miabc_benefits!=='' || stateMessage.email!=='' || stateMessage.wallet_address!==''){
    message =  <div className={`text-center alert alert-${alert} alert-dismissible fade show`} role="alert">
                              {stateMessage.miabc_benefits}{stateMessage.wallet_address}{stateMessage.email}
                          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                          </button>
                          </div>
    }

    if(messageAddress!=='' || messageHash!==''){
      message =  <div className={`text-center alert alert-danger alert-dismissible fade show`} role="alert">
                                {messageAddress} {messageHash}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
      }
    
    useEffect(() => {
      
        localStorage.setItem('lang', base_url.substring(1))	
		SetLang("/" + localStorage.getItem('lang'))
        setLoading(false)
    }, [])
    let language = new LocalizedStrings(
        langFile,
        {
            customLanguageInterface: customInterfaceFunction
        }
        ); 

    
    const getPrice = async (contract) =>{
      const priceWei = await contract.PRICE();
      const priceEther = ethers.utils.formatEther(priceWei);
      return priceEther;
    }

    const getToken = () => {
      let token = moment.utc().valueOf(); 
      /* let token = new Date(); */
      /* token = token.getUTCMinutes(); */
      /* console.log(token); */
      return token;
  }

    const saveMint = async (address, hash) =>{
        try{
          const accounts = await ethereum.request({ method: 'eth_accounts' });
          const account = accounts[0];
          
          let config = {
            method: 'POST',
            headers:{
              'Authorization' : getToken() + account,
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({wallet_address: address, hash: hash})
          }
          let res = await fetch(`${api_base_url}/mints`, config)
          let json = await res.json()
          
          if(json.error){          
            setMessageAddress(
              json.error ? (json.error.wallet_address ? json.error.wallet_address[0] : '') : '',
            )
            setMessageHash(
              json.error ? (json.error.hash ? json.error.hash[0] : '') : '',
            )
            return null;  
          }
          return json.id;
        }catch (error){
          /* console.log(error)  */ 
        }
    }
    /* const rollBackMint = async (id) =>{
      try{
        let config = {
          method: 'POST',
          headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({mint_id: id})
        }
        let res = await fetch(`${base_url}/mints_rollback`, config)
        await res.json()
        console.log("Done! rolled back");
      }catch (error){
        console.log(error)  
      }
  } */
    const mintNftHandler = async () => {
        setLoading(true)
        try {    
          if (ethereum) {
            const accounts = await ethereum.request({ method: 'eth_accounts' });
            await ethereum.request({ method: 'eth_requestAccounts' });
            /* console.log(accounts[0]) */
            /* const provider = new ethers.providers.JsonRpcProvider('https://data-seed-prebsc-1-s1.binance.org:8545', { name: 'testnet', chainId: 97 }) */
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            const nftContract = new ethers.Contract(contractAddress, ContractABI, signer);
            const priceEther = await getPrice(nftContract);
            let nftTxn = await nftContract.mintNFTs(cant_mint, { value: ethers.utils.parseEther(priceEther) });
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: language.messageMetamaskWait,
                text: language.messageMintWait,
                showConfirmButton: false,
                timer: 3500
            })
            await nftTxn.wait();
            let idMint = await saveMint(accounts[0], nftTxn.hash);
            setLoading(false)
            const link = `https://testnet.bscscan.com/tx/${nftTxn.hash}`;
            const element = document.createElement('div')
            /* element.innerHTML = language.messageMinted + "<a href="+link+">"+language.messageBscscan+"</a>"; */
            element.innerHTML = language.messageMinted + "<a href="+idMint+">"+idMint+"</a>";
            const swalWithBootstrapButtons = Swal.mixin({
              confirmButtonColor : '#ED4A3A',
            })  
            swalWithBootstrapButtons.fire({
                position: 'center',
                icon: 'success',
                title: language.messageMetamaskSuccess,
                html: language.messageMinted +
                  '<a href="'+link+'" target="_blank">'+language.messageBscscan+'</a>',  
                showConfirmButton: true
            })   
          } else {
            setLoading(false)
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 4500,
                timerProgressBar: true,
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })
              
              Toast.fire({
                icon: 'error',
                title: language.messageMetamaskError,
                text: language.messageMetamaskInstall,
    
              })
          }
    
        } catch (err) {
            setLoading(false)
            let res = "Unknown Error";
            /* console.log(err) */
            if(err.code === alreadyRequested){

                res = language.messageMetamask2;
                
            }else if(err.code === rejected){

                res = language.messageMetamask1;
                
            }else if(err.data.code === notFunds){

                res = language.messageMetamask3;

            }else if(err.data.code === notNfts){
                
                res = language.messageMetamask4;

            }
            
            /* Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: language.messageMetamaskError,
                text: res,
                showConfirmButton: false,
                timer: 4500
            }) */
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })
              
              Toast.fire({
                icon: 'error',
                title: language.messageMetamaskError,
                text: res,
    
              })
          //instancia de error cuando rechaza, se accesaría err.code... code: 4001, message: 'MetaMask Tx Signature: User denied transaction signature.'
          //instancia de error cuando ya hay una peticion de loggeo pendiente code: -32002, message: 'Already processing eth_requestAccounts.
        }
    }
    
    
    if(loading)
		return <Loading />
    
    return <Home mintNftHandler = {mintNftHandler}
                 handleShow = {handleShow}
                 handleClose = {handleClose}
                 show = {show}
                 /* handleSubmit = {handleSubmit}
                 handleChange = {handleChange}
                 handleChangeRegion = {handleChangeRegion}
                 handleChangeCountry = {handleChangeCountry} */
                 message={message} 
                 state = {state}
                 myAccountMenu = {props.myAccountMenu}

    />

}

export default HomeContainer