import React from 'react'
import {Link} from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';
/* import base_url from '../base_url' */
import lang from '../../components/languages/lang'
import LocalizedStrings from 'localized-strings';
import customInterfaceFunction from '../../components/languages/customInterfaceFunction';
import base_url from '../../base_url'
import base_url_assets from '../../base_url_assets';

const Menu = ({isOwner, currentAccount, connectWalletHandler, mintNftHandler, address, isMyAccount, handlerSetIsMyAccount, myAccountMenu, currentAccountMinted}) => {
  
    let language = new LocalizedStrings(
        lang,
        {
            customLanguageInterface: customInterfaceFunction
        }
        ); 
    let english = "";
    let spanish = "";
    if(localStorage.getItem('lang') === 'en'){
    
        english =<Link to="#"><img src={base_url_assets+"/assets/images/flags/english.png"} className="me-2" alt=""></img>{language.english}</Link> 
        
    }else{
        english =<Link to={"/en/change-language"}><img src={base_url_assets+"/assets/images/flags/english.png"} className="me-2" alt=""></img>{language.english}</Link>    
    
    }
    if(localStorage.getItem('lang') === 'es'){
        spanish =<Link to="#"><img src={base_url_assets+"/assets/images/flags/spain.png"} className="me-2" alt=""></img>{language.spanish}</Link> 
    }else{
        spanish =<Link to={"/es/change-language"}><img src={base_url_assets+"/assets/images/flags/spain.png"} className="me-2" alt=""></img>{language.spanish}</Link> 
        
    }

    let file = "/downloads/whitepaper.pdf";     
    if(localStorage.getItem("lang") === "en"){
        file = "/downloads/whitepaper_en.pdf";
    }
    let myAccount=""
    if(currentAccountMinted > 0 || isOwner){
        myAccount = <li className="nav-item">
                        <Link to={base_url + "/my-account"}>{language.myAccount}</Link>                     
                    </li>
    }

    let menuContent = ""
    
    
    if(myAccountMenu === false){
         menuContent =   <ul id="nav" className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <HashLink to={"#home"}  className="page-scroll active"  aria-label="Toggle navigation">{language.home}</HashLink>
                                </li>
                                <li className="nav-item">
                                    <HashLink to={{
                                            hash: "#features"
                                            }} 
                                        className="page-scroll" aria-label="Toggle navigation">{language.features}
                                    </HashLink>
                                </li>
                                <li className="nav-item">
                                    <HashLink to="#pricing" className="page-scroll"
                                        aria-label="Toggle navigation">{language.package}</HashLink>
                                </li>
                                <li className="nav-item">
                                    <HashLink to="#team" className="page-scroll" aria-label="Toggle navigation">{language.team}</HashLink>
                                </li>
                                <li className="nav-item">
                                    <Link to={file} target="_blank" download>Whitepaper</Link>
                                    
                                </li>                                             
                                <li className="nav-item">
                                    <Link className="dd-menu collapsed" to="#" data-bs-toggle="collapse"
                                        data-bs-target="#submenu-1-4" aria-controls="navbarSupportedContent"
                                        aria-expanded="false" aria-label="Toggle navigation">{language.language}</Link>
                                    <ul className="sub-menu collapse" id="submenu-1-4">
                                        <li className="nav-item"><span>{spanish}</span>
                                        </li>
                                        <li className="nav-item"><span>{english}</span>
                                        </li>
                                    </ul>
                                </li>
                                {myAccount}
                            </ul>
    }else{
            menuContent =   <ul id="nav" className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <Link to={base_url}  /* className="page-scroll" */  /* aria-label="Toggle navigation" */>{language.home}</Link>
                                </li>                                           
                                <li className="nav-item">
                                    <Link className="dd-menu collapsed" to="#" data-bs-toggle="collapse"
                                        data-bs-target="#submenu-1-4" aria-controls="navbarSupportedContent"
                                        aria-expanded="false" aria-label="Toggle navigation">{language.language}</Link>
                                    <ul className="sub-menu collapse" id="submenu-1-4">
                                        <li className="nav-item"><span>{spanish}</span>
                                        </li>
                                        <li className="nav-item"><span>{english}</span>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <Link to={base_url + "/my-account"} className="active">{language.myAccount}</Link>   
                                </li>
                            </ul>
    }
    return( 
        <>  
            <header className="header navbar-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="nav-inner">
                                
                                <nav className="navbar navbar-expand-lg">
                                    <HashLink className="navbar-brand" to={"/"}>
                                        <img src={base_url_assets+"/assets/images/logo/white1.png"} alt="Logo"/>
                                    </HashLink>
                                    <button className="navbar-toggler mobile-menu-btn" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                        aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="toggler-icon"></span>
                                        <span className="toggler-icon"></span>
                                        <span className="toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                                        {menuContent}  
                                        <div className="d-flex justify-content-center justify-content-sm-start button add-list-button">
                                            <button type="button" onClick={!currentAccount ? connectWalletHandler : void 0} className={currentAccount ? "btn" : "btn"}>{currentAccount ? address : language.walletConnect}
                                            </button>
                                        </div>
                                    </div>
                                    
                                </nav>
                                
                            </div>
                        </div>
                    </div> 
                </div> 
            </header>
    
            
        </>   
    )
}

export default Menu