import React, { useState, useEffect} from 'react'
import Menu from './Menu'
/* import contract from '../contracts/Abi.json' */
import { ethers } from 'ethers';
import { ContractABI } from "../../contracts/Abi";
import { contractAddress } from "../../contracts/Contract";
import Swal from 'sweetalert2'
import lang from '../../components/languages/lang'
import LocalizedStrings from 'localized-strings';
import customInterfaceFunction from '../../components/languages/customInterfaceFunction';
import base_dev from '../../base_url_dev';
/* const abi = contract.abi; */

const { ethereum } = window;
const notFunds = -32000;
const notNfts = 3;
const rejected = 4001;
const alreadyRequested = -32002;
const provider = new ethers.providers.Web3Provider(ethereum);
const signer = provider.getSigner();

const MenuContainer = ({myAccountMenu}) => {
    let language = new LocalizedStrings(
      lang,
      {
          customLanguageInterface: customInterfaceFunction
      }
      ); 
    
      /* const [isConnecting, setIsConnecting] = useState(false); */
      const [currentAccountMinted, setCurrentAccountMinted] = useState(null);
      const [currentAccount, setCurrentAccount] = useState(null);
      const [isOwner, setIsOwner] = useState(false);
    
      const checkWalletIsConnected = async () => {
          
      
          if (!ethereum) {
            /* console.log("Make sure you have Metamask installed!"); */
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: language.messageMetamaskError,
              text: language.messageMetamask,
              showConfirmButton: false,
              timer: 3500
            })
            return;
          } else {
            const accounts = await ethereum.request({ method: 'eth_accounts' });
          
            /* if(currentAccount){
              const provider = new ethers.providers.Web3Provider(ethereum);
              const signer = provider.getSigner()
              await signer.signMessage(language.signMessage);

            } */
            if (accounts.length > 0) {
              const account = accounts[0];
              /* console.log("Found an authorized account: ", account); */
              setCurrentAccount(account);
              localStorage.setItem("currentAccount", account);

              //Checking if user have at least 1 mint
              /* const provider = new ethers.providers.Web3Provider(ethereum);
              const signer = provider.getSigner(); */
              const nftContract = new ethers.Contract(contractAddress, ContractABI, signer); 
              /* console.log("antes");  */
              let tokenBalance = await nftContract.balanceOf(account);
              setCurrentAccountMinted(tokenBalance);
              /* let tokenBalance = await nftContract.ownerOf("0"); */
              tokenBalance = tokenBalance.toString();
              /* console.log(tokenBalance); */
              /* console.log(account); */  
            } else {
              /* console.log("No authorized account found"); */
              /* window.location.replace("/"); */
              localStorage.removeItem("currentAccount");
            }
            /* console.log("Wallet exists! We're ready to go!"); */
            /* Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Success',
              text: "You have Metamask installed. We're ready to go!",
              showConfirmButton: false,
              timer: 3500
            }) */
          }              
      }
      const getOwnerHandler = async () => {
        try {
          if (ethereum) {
            /* const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner(); */
            const nftContract = new ethers.Contract(contractAddress, ContractABI, signer);  
            let owner = await nftContract.owner();
            const [account] = await ethereum.request({ method: 'eth_requestAccounts' });    
            if (owner.toLowerCase() === account.toLowerCase()) {
                setIsOwner(true);
            }
          }else {
          }
        } catch (error) {
        }
      }
      const connectWalletHandler = async () => {
          /* console.log("ddd") */
          

          if (!ethereum) {        
            /* Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: language.messageMetamaskError,
              text: language.messageMetamaskInstall,
              showConfirmButton: false,
              timer: 3500
            }) */
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 4500,
              timerProgressBar: true,
              iconColor: 'white',
              customClass: {
                  popup: 'colored-toast'
              },
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })
            
            Toast.fire({
              icon: 'error',
              title: language.messageMetamaskError,
              text: language.messageMetamaskInstall,

            })
          }

          try {              
              const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
              /* const provider = new ethers.providers.Web3Provider(ethereum);
              const signer = provider.getSigner()
              await signer.signMessage(language.signMessage); */
                
              setCurrentAccount(accounts[0]);
              localStorage.setItem("currentAccount", accounts[0]);

          } catch (err) {
            let res = "Unknown Error";
            /* console.log(err) */
            if(err.code === alreadyRequested){

                res = language.messageMetamask2;
                
            }else if(err.code === rejected){

                res = language.messageMetamask1;
                
            }else if(err.data.code === notFunds){

                res = language.messageMetamask3;

            }else if(err.data.code === notNfts){
                
                res = language.messageMetamask4;

            }
              /* console.log(err) */
              /* Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: language.messageMetamaskError,
                text: language.messageMetamaskFoundError,
                showConfirmButton: false,
                timer: 3500
              }) */
              const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })
              
              Toast.fire({
                icon: 'error',
                title: language.messageMetamaskError,
                text: res,
    
              })
          }


      }
      useEffect(() => {
         /* if( accounts && accounts[0])
          props.onAcountChange(accounts[0], true) */
          getOwnerHandler();
          checkWalletIsConnected();
          const checkChange = async () => {
            
            try{
              if(ethereum) {                                
                ethereum.on('chainChanged', async () => {
                  const accounts = await ethereum.request({ method: 'eth_accounts' });
                  localStorage.setItem("currentAccount", accounts[0]);
                  window.location.replace(base_dev+"/");
                });
               /*  ethereum.on('disconnect', () => {
                  localStorage.removeItem("currentAccount");
                  window.location.replace("/");
                  console.log("hi");
                }); */
                ethereum.on('accountsChanged', async () => {  
                    const accounts = await ethereum.request({ method: 'eth_accounts' });
                    if(accounts.length === 0){
                      window.location.replace(base_dev+"/");                                 
                    }
                    /* const provider = new ethers.providers.Web3Provider(ethereum);
                    const signer = provider.getSigner(); */

                    const signature = await signer.signMessage(language.signMessage)

                    localStorage.setItem("currentAccount", accounts[0]);
                    const Toast = Swal.mixin({
                      toast: true,
                      position: 'top-end',
                      showConfirmButton: false,
                      timer: 2000,
                      timerProgressBar: true,
                      iconColor: 'white',
                      customClass: {
                          popup: 'colored-toast'
                      },
                      didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                      }
                    })
                    
                    Toast.fire({
                      icon: 'success',
                      title: language.messageMetamaskSuccess,
                      text: language.messageMetamaskFound,
      
                    })
                    setTimeout(() => {
                      window.location.replace(base_dev+"/");                                  
                    }, 2000);   
      
                })
              }
            }catch(err){
              console.log("aqui" + err);
            }
          }  
          checkChange();
          return () => {
            
            ethereum.removeListener('accountsChanged', checkChange);
            ethereum.removeListener('chainChanged', checkChange);
            ethereum.removeListener('disconnect', checkChange);
          }      
      },[])
      /* useEffect(() => {
        checkWalletIsConnected();
    }, [currentAccountMinted]) */
      let address = ''
      if(currentAccount){
          let addressLast = currentAccount.slice(-4);
          let addressFirst = currentAccount.slice(0, 4);
          address = addressFirst + "..." + addressLast
      }

      return <Menu
          currentAccount = {currentAccount}
          isOwner = {isOwner}
          address = {address}
          connectWalletHandler = {connectWalletHandler}
          /* isMyAccount = {isMyAccount}
          handlerSetIsMyAccount = {handlerSetIsMyAccount} */
          myAccountMenu = {myAccountMenu}
          currentAccountMinted = {currentAccountMinted}
          /* setIsMyAccount = {setIsMyAccount} */
          
      />

  }

export default MenuContainer