let language = {
    es: {
        home: "Inicio",
        features: "Características",   
        about : "Nosotros",
        whitepaper : "Whitepaper",
        whitepaperSub : "Descripción detallada del proyecto, donde podrás ver la razón de ser y objetivos del mismo.",
        package : "Productos",
        team : "Equipo",
        language : "Idioma",
        title : "¡Aprende y gana con Charly, MIABC y más!",
        subtitle : "Adquiere nuestro NFT y obtén todos nuestros beneficios, incluyendo la participación en nuestros concursos para ganar dinero.",
        getNft : "Obtener NFTs de Charly",
        walletConnect : "Conectar Billetera",
        languages : "Languages",
        english : "Inglés",
        spanish : "Español",
        featureTitle : "Tu experiencia es cada vez mejor con todas las funciones de MIABC",
        featureTitle2 : "Comprueba como puede mejorar la experiencia del aprendíz con lo siguiente.",
        feature1 : "9 Módulos: Alfabeto, Sonidos, Palabras, Completar, Familia, Colores, Números, Figuras geométricas, Ya se leer.",
        feature1Title : "Módulos",
        feature2 : "Contiene más de 300 imágenes con sus respectivos sonidos.",
        feature2Title : "Contenido",
        feature3 : "Las imágenes y sonidos pueden ser sustituidos por elementos multimedia propias, ya sean grabados por ti o tomados directamente de tu galería.",
        feature3Title : "Personalización",       
        feature4 : "Facilita el aprendizaje a través del uso de marcadores.",
        feature4Title : "Marcadores",       
        feature5 : "Involucra a toda la familia en el proceso de aprendizaje más importante para el aprendiz, a través del uso de frases de Felicitación y personalización de las tarjetas.",
        feature5Title : "Aprendizaje en familia",              
        feature6 : "Permite compartir palabras entre aprendices del mismo grado.",
        feature6Title : "Intercambio",
        downloadMiabc : "Descarga MIABC",
        downloadSub : "Puedes descargar nuestra App desde las siguientes plataformas:",
        meet : "Conoce a nuestro equipo.",
        featureCourses : "Nuestros cursos destacados",
        featureCoursesDesc : "Puedes ver todo sobre nuestros cursos destacados en esta sección.",
        meetSub : "¡Conoce al equipo de trabajo de diazappsnfts!",
        packs : "Adquiere tus NFTs",
        packsSub : "Elige el producto que te parezca más atractivo, ten en cuenta los beneficios que obtendrás con cada paquete.",
        cartoon : "Caja Completa",
        pixelArt : "Caja Especial",
        customCards : "Tarjetas personalizadas",
        cartoonSub : "Obtén el NFT de Charly junto a MIABC y todos los demás beneficios.",
        pixelArtSub : "Paquete de MIABC especial",
        customArtSub : "Sube tus imágenes y obtén las tarjetas que quieres incluir como NFTs en tu producto",
        buyCartoon : "Obtener Caja Completa",
        buyPixelArt : "Obtener Caja Especial",
        buyCustom: "Hacer Pedido (Próximamente)",
        included : "Lo que incluye",
        basic1 : "Caja de MIABC con todas las tarjetas incluidas.",
        basic2 : "Una tarjeta física de Charly (La imagen de la app), incluída en la caja.",
        basic3 : "Un código que brinda acceso a la app.",
        basic4 : "Todas las tarjetas digitales dentro de la app.",
        basic5 : "Una tarjeta coleccionable digital de Charly (NFT).",
        basic6 : "Acceso al curso \"Creación de Heridas Falsas (Efectos especiales)\" en nesheracademy.com.",
        basic7 : "Acceso al curso \"Aprende a bailar Kpop desde cero\" en nesheracademy.com.",
        basic8 : "Acceso al curso \"Tequeños Venezolanos\" en nesheracademy.com.",
        basic9 : "Acceso al curso \"Aprende a dibujar personajes impactantes\" en nesheracademy.com.",
        premium1 : "Caja de MIABC con todas las tarjetas incluidas.",
        premium2 : "Una tarjeta física de Charly (La imagen de la app).",
        premium3 : "Un código que brinda acceso a la app.",
        premium4 : "Todas las tarjetas digitales dentro de la app.",             
        premium5 : "Una tarjeta coleccionable digital de Charly (NFT).",
        premium6 : "Acceso al curso \"Economía digital\", en nesheracademy.com.",
        custom1 : "Próximamente",
        footer1 : "Tu experiencia es cada vez mejor con todas nuestras funciones",
        footer2 : "Diseñado y desarrollado por", 
        footer3 : "MIABC",
        footer4 : "Legal",  
        footer5 : "Términos & Condiciones",
        footer6 : "Política de privacidad",
        familyCards : "Tarjetas Familiares",
        messageMetamask : "Asegúrate de que tienes Metamask instalado en tu navegador.",
        messageMetamaskError : "Error",
        messageMetamaskSuccess : "Éxito",
        messageMetamaskInstall : "Por favor instala Metamask en tu navegador.",
        messageMetamaskFound : "¡Estás Conectado!",
        messageMetamaskFoundError : "Error Conectando.",
        messageMetamaskWait : "Info",
        messageMintWait : "Procesando... Por favor espere...",
        messageMinted : "Transacción completada exitosamente.",
        messageMetamask1 : "Transacción Rechazada.",
        messageMetamask2 : "Ya hay una petición de Metamask en proceso, por favor chequea el icono de la extensión de metamask.",
        messageMetamask3 : "Fondos Insuficientes",
        messageMetamask4 : "No quedan suficientes NFTs para realizar esta transacción.",
        messageBscscan : "Ver en Bscscan",
        getBox : "¡Obtén tu kit completo ya!",
        course1 : "Aprende a dibujar personajes impactantes",
        course1Desc : "El curso de APRENDE A DIBUJAR PERSONAJES IMPACTANTES  está dirigido a todo público, principalmente a niños, ilustradores, diseñadores y creativos. La idea es, en el caso de los principiantes, que a través de ejercicios prácticos aprendan a soltar la mano y dejar en libertad a la imaginación para crear, cada quien, sus propios personajes impactantes (que impacten por su forma, tamaño o color).",
        course2 : "Creación de Heridas Falsas (Efectos especiales)",
        course2Desc : "En este curso se enseñará a realizar una herida falsa con técnicas básicas para darle un look profesional a la herida. El alumno saldrá realizando una herida falsa sin tomarle mucha preparación y con materiales que puede encontrar tanto en casa como en cualquier tienda de arte. Este curso es de nivel básico para realizar posteriormente otros más avanzados.",
        course3 : "Aprende a bailar Kpop desde cero",
        course3Desc : "Se trata de un curso básico dirigido a todas aquellas personas que se interesan en la cultura Asiática y en el movimiento ahora muy reconocido a nivel mundial llamado KPOP. El baile, en su total expresión, te permite elevarte física y espiritualmente, el baile es vida, él te enseña que todo estará bien si solo respiras y BAILAS.",
        course4 : "Tequeños Venezolanos",
        course4Desc : "El curso de TEQUEÑOS VENEZOLANOS está dirigido a todas aquellas personas, chicos, jóvenes y adultos, que deseen aprender a hacer estos deliciosos “pasapalos” o “abrebocas” venezolanos, para degustarlos en la familia; y muy especialmente va dirigido a aquellas personas que deseen iniciar un emprendimiento o negocio vendiendo estos deliciosos aperitivos en sus comunidades. El TEQUEÑO es una receta sencilla de armar bastones de queso que van a ser envueltos por la masa que vamos a preparar. Una vez armados, se fríen, degustan y disfrutan en cualquier ocasión.",
        signMessage : "Haga click en \"Firmar\" para conectarse a www.diazappsnfts.com",
        myAccount : "Mi Cuenta",
        mintData : "Datos de minteos",
        email : "Correo Electrónico del beneficiario",
        address : "Dirección 1",
        address2 : "Dirección 2",
        mailingName : "Nombre",
        mailingCountry : "País",
        mailingCity : "Región",
        mailingZip : "Código Postal",
        mailingPhone : "Teléfono",
        mailingBenefits : "¿Quieres recibir la caja física de MIABC?",
        mailingSubmit : "Procesar",
        mailingClose : "Cerrar",
        myNfts : "Mis NFTs",
        contests : "Concursos" ,
        myMintMessage : "No tienes minteos.",
        noEntries : "No hay concursos en progreso.",
        myMints : "Mis Minteos",
        seeIn : "Ver en",
        fillForm : "Llenar formulario",
        editForm : "Editar formulario",
        helpfill : "Completa la información necesaria para el envío de los productos MIABC/Mi1erabcdario y registro en nesheracademy.com",
        addressPlace : "Calle, avenida",
        address2Place : "Casa, apartamento, estudio, piso",
        nesherData : "Datos de nesheracademy.com",
        emailNote : "Este correo será registrado en la plataforma de nesheracademy y podrás usarlo para acceder a la misma.",
        miabcData : "Datos de MIABC/Mi1erabecedario",
        formCheck : "No recibir caja física (Mi1erabecedario).",
        formCheckNote : "Marca esta opción si no quieres que te enviemos la caja de Mi1erabecedario.",
        emailRequired : "El Email es requerido",
        addressRequired : "La Direccion 1 es requerida",
        address2Required : "La dirección 2 es requerida",
        countryRequired : "El País es requerido",
        stateRequired : "La Región es requerida",
        codeRequired : "El Código Postal es requerido",
        errorMessage : "Por favor completa todos los campos necesarios",
        errorMessage1 : "Este curso no esta activo.",
        doneMessage : "Los datos han sido enviados satisfactoriamente",
        done : "Listo",
        error : "Error",
        charlyDesc : "Este es charly en un marco de",
        charlyDesc2 : "",
        frameWood : "Madera",
        frameSilver : "Plata",
        frameGold : "Oro",
        frameDiamond : "Diamante",
        frameEmerald : "Esmeralda",
        charlyDesc3 : "Y se siente",
        sad : "Triste",
        happy: "Felíz",
        surprise : "Sorprendido",
        angry : "Enfadado",
        friendly : "Amigable",
        upload_files : "Cargar Archivos",
        edit_files : "Editar información",
        contestPass : "Pase de concurso",
        contest : "Concurso",
        start : "Comienza el",
        end : "Finaliza el",
        beneficiary : "Email del beneficiario",
        noContestData : "No hay datos, por favor rellena los formularios en la sección \"Datos de minteos\" para activar tus pases de concursos.",
        contestData : "Datos del participante",
        name : "Nombre del participante",
        emailPart : "Correo electrónico del participante",
        filesData : "Archivos",
        certificateDesc : "Sube el certificado de completación del curso",
        multimediaDesc : "Sube la imagen de tu producto final",
        certificate : "Certificado de completación del curso",
        multimedia : "Imagen/Video del producto",
        multimedia2 : "Video del baile realizado",
        multimediaDesc2 : "Sube el video del baile realizado",
        filesSubmit : "Enviar",
        sizeError : "El tamaño de la archivo debe ser menor a 2MB",
        sizeError2 : "El tamaño del archivo debe ser menor a 10MB",
        current : "Actual",
        countText : "Finaliza en",
        activeCourse : "En curso",
        finishedText : "Concurso finalizado",
        luckText : "Resultado:",
        noWinText : "No has calificado entre las primeras posiciones, ánimo siempre habrá más concursos.",
        winText : "¡Felicidades! Has quedado en el",
        first : "1er lugar.",
        second : "2do lugar.",
        third : "3er lugar.",
        admin : "Admin contrato",
        entries : "Pases de concurso actual",
        setUrl : "Actualizar Url Base",
        setPrice : "Actualizar Precio",
        setSupply : "Actualizar Supply Máximo",
        setMaxMint : "Actualizar Máximo por Minteo",
        updateButton : "Actualizar",
        noParticipate : "Este beneficiario no esta participando.",

    },
    en: {
        home: "Home",    
        features: "Features",
        about : "About us",
        whitepaper : "Whitepaper",
        whitepaperSub : "Detailed description of the project, where you can see the reason of it and its objectives.",
        package : "Products",
        team : "Team",
        language : "Language",
        title : "learn and earn with Charly, MIABC and more!",
        subtitle : "Get our NFT and get all our benefits including participation on the contests we offer to earn money.",
        getNft : "Get Charly NFTs",
        walletConnect : "Connect Wallet",
        languages : "Lenguajes",
        seeAs : "Ver como",
        english : "English",
        spanish : "Spanish",
        account : "Perfil",
        featureTitle : "Your Experience Gets Better And Better with all of MIABC features",
        featureTitle2 : "Check how the learner experience can be improved with the following stuff.",
        feature1 : "9 Modules: Alphabet, Sounds, Words, Complete, Family, Colors, Numbers, Geometric Figures, I can read.",
        feature1Title : "Modules",
        feature2 : "Contains more than 300 images with their respective sounds .",
        feature2Title : "Content",
        feature3 : "Images and sounds can be replaced by your own multimedia elements, either recorded by you or taken directly from your gallery.",   
        feature3Title : "Customization",       
        feature4 : "Makes learning more practical by the use of bookmarks.",
        feature4Title : "Bookmarks",
        feature5 : "Involves the whole family in the most important learning process for the learner, through the use of congrats phrases and customization of flashcards.",
        feature5Title : "Family learning",
        feature6 : "Allows to share words between learners of the same grade.",
        feature6Title : "Sharing",
        downloadMiabc : "Download MIABC",
        downloadSub : "You can download our App from the following platforms:",
        meet : "Meet our team.",
        featureCourses : "Our featured courses",
        featureCoursesDesc : "You can see all of our featured courses in this section.",
        meetSub : "Meet the diazappsnfts team!",
        packs : "Get your NFTs",
        packsSub : "Choose the product that looks most attractive to you, keep in mind the benefits you will get with each package.",
        cartoon : "Full Box",
        pixelArt : "Featured Box",
        customCards : "Custom Flashcards",
        cartoonSub : "Get Charly's NFT with MIABC and all the other features.",
        pixelArtSub : "Featured MIABC pack.",
        customArtSub : "Upload your images and get the flashcards you want as NFTs for your product",
        buyCartoon : "Get Full Box",
        buyPixelArt : "Get Featured Box",
        buyCustom: "Place an order (Coming Soon)",
        included : "What's Included",
        basic1 : "MIABC physical box with the flashcards included.",
        basic2 : "A physical flashcard of Charly (The image of the app), included into the box.",
        basic3 : "A code that provides access to the app.",
        basic4 : "All the digital flashcards into the app.",
        basic5 : "A digital collectible Charly flashcard (NFT).",
        basic6 : "Acceso al curso \"Creación de Heridas Falsas (Efectos especiales)\" en nesheracademy.com.",
        basic7 : "Acceso al curso \"Aprende a bailar Kpop desde cero\" en nesheracademy.com.",
        basic8 : "Acceso al curso \"Tequeños Venezolanos\" en nesheracademy.com.",
        basic9 : "Access to \"Aprende a dibujar personajes impactantes\" course in nesheracademy.com.",
        premium1 : "MIABC physical box with the flashcards included.",
        premium2 : "A physical flashcard of Charly (The image of the app).",
        premium3 : "A code that provides access to the app.",
        premium4 : "All the digital flashcards into the app.",
        premium5 : "A digital collectible Charly flashcard (NFT).",
        premium6 : "Acceso al curso \"Economía digital\", en nesheracademy.com.",
        custom1 : "Coming Soon",
        footer1 : "Your Experience Gets Better And Better With All Of Our Features",
        footer2 : "Designed and Developed by",
        footer3 : "MIABC",
        footer4 : "Legal",
        footer5 : "Terms & Conditions",
        footer6 : "Privacy Policy",
        familyCards : "Family Flashcards",
        messageMetamask : "Make sure you have Metamask installed.",
        messageMetamaskError : "Error",
        messageMetamaskSuccess : "Success",
        messageMetamaskInstall : "Please install Metamask in your browser.",
        messageMetamaskFound : "You're Connected!",
        messageMetamaskFoundError : "Error Connecting.",
        messageMetamaskWait : "Info",
        messageMintWait : "Minting... Please Wait...",
        messageMinted : "Transaction completed successfully.",
        messageMetamask1 : "Transaction Rejected.",
        messageMetamask2 : "Already processing a Metamask request, please check the metamask extension icon.",
        messageMetamask3 : "Insufficient Funds",
        messageMetamask4 : "Not enough NFTs available for this transaction.",
        messageBscscan : "See on Bscscan",
        getBox : "Get your full kit now!",
        course1 : "Learn to draw impressive characters",
        course1Desc : "The LEARN TO DRAW IMPRESSIVE CHARACTERS course is aimed at all audiences, mainly children, illustrators, designers and creatives. The idea is, in the case of beginners, that through practical exercises they learn to let go of the hand and leave their imagination free to create, each one, their own impressive characters (which impact due to their shape, size or color).",
        course2 : "Creation of fake wounds (special effects)",
        course2Desc : "This course will teach you how to make a fake wound with basic techniques to give the wound a professional look. The student will come out making a fake wound without taking much preparation and with materials that can be found both at home and in any art store. This course is basic level to later take more advanced ones.",
        course3 : "Learn to dance Kpop from scratch",
        course3Desc : "It is a basic course aimed at all those people who are interested in Asian culture and in the movement now widely recognized worldwide called KPOP. Dance, in its full expression, allows you to rise physically and spiritually, dance is life, it teaches you that everything will be fine if you just breathe and DANCE.",
        course4 : "Venezuelan Tequeños",
        course4Desc : "The VENEZUELAN TEQUEÑOS course is aimed to all those people, children, youth and adults, who wish to learn how to make these delicious Venezuelan “snacks” or “appetizers”, to taste them in the family; and very especially it is aimed at those people who wish to start a venture or business selling these delicious appetizers in their communities. TEQUEÑO is a simple recipe for assembling cheese sticks that are going to be wrapped in the dough that we are going to prepare. Once assembled, they are fried, tasted and enjoyed on any occasion.",
        signMessage : "Click on \"Sign\" to connect www.diazappsnfts.com",
        myAccount : "My Account",
        mintData : "Minting Data",
        email : "Beneficiary Email",
        address : "Address 1",
        address2 : "Address 2",
        mailingName : "Name",
        mailingCountry : "Country",
        mailingCity : "Region",
        mailingZip : "Zip Code",
        mailingPhone : "Phone",
        mailingBenefits : "Do yo want to get MIABC physical box?",
        mailingSubmit : "Submit",
        mailingClose : "Close",
        myNfts : "My NFTs",
        contests : "Contests",
        myMintMessage : "You do not have any mints.",
        noEntries : "There are no contests in progress.",
        myMints : "My Mints",
        seeIn : "See in",
        fillForm : "Fill Form",
        editForm : "Edit Form",
        helpfill : "Complete the necessary information to send MIABC/Mi1erabcdario products and register in nesheracademy.com",
        addressPlace : "Main street, Avenue",
        address2Place : "Apartment, studio, floor",
        nesherData : "nesheracademy data",
        emailNote : "This email will be registered on the nesheracademy platform and you could use it to access it.",
        miabcData : "MIABC/Mi1erabecedario data",
        formCheck : "I do not want to receive a physical box (Mi1erabecedario).",
        formCheckNote : "Check this option if you do not want us to send you the Mi1erabecedario box.",
        emailRequired : "The Email is required",
        addressRequired : "The Address 1 is required",
        address2Required : "The Address 2 is required",
        countryRequired : "The Country is required",
        stateRequired : "The Region is required",
        codeRequired : "The Zip Code is required",
        errorMessage : "Please fill all the required fields",
        errorMessage1 : "This course is not in progress.",
        doneMessage : "The data has been sent successfully",
        done : "Done",
        error : "Error",
        charlyDesc : "This is Charly framed in a",
        charlyDesc2 : "picture frame",
        frameWood : "Wood",
        frameSilver : "Silver",
        frameGold : "Gold",
        frameDiamond : "Diamond",
        frameEmerald : "Emerald",
        charlyDesc3 : "And he feels",
        sad : "Sad",
        happy: "Happy",
        surprise : "Surprised",
        angry : "Angry",
        friendly : "Friendly",
        upload_files : "Upload files",
        edit_files : "Edit info",
        contestPass : "Contest pass",
        contest : "Contest",
        start : "Starts on",
        end : "Ends on",
        beneficiary : "Beneficiary email",
        noContestData : "No contest data, please fill the forms on the \"Minting data\" section to activate your contest passes.",
        contestData : "Participant data",
        name : "Participant name",
        emailPart : "Participant email",
        filesData : "Files",
        certificateDesc : "Upload the certificate of completion",
        multimediaDesc : "Upload the final product image",
        certificate : "Certificate of completion",
        multimedia : "Product image/video",
        multimedia2 : "Dancing video",
        multimediaDesc2 : "Upload the dancing video",
        filesSubmit : "Send",
        sizeError : "The file size must be less than 2MB",
        sizeError2 : "The file size must be less than 10MB",
        current : "Current",
        countText : "Ends in",
        activeCourse : "In progress",
        finishedText : "This contest has finished",
        luckText : "Result:",
        noWinText : "You have not qualified, cheer up there will always be more contests.",
        winText : "Congratulations! You have qualified in the",
        first : "1st place.",
        second : "2nd place.",
        third : "3rd place.",
        admin : "Contract Admin",
        entries : "Current contest entries",
        setUrl : "Set base URL",
        setPrice : "Set Price",
        setSupply : "Set Max Supply",
        setMaxMint : "Set Max Per Mint",
        updateButton : "Update",
        noParticipate : "This beneficiary is not participating",


    }
};

export default language
