import React from "react";
import lang from './languages/lang'
import LocalizedStrings from 'localized-strings';
import customInterfaceFunction from './languages/customInterfaceFunction';
import ReactTooltip from 'react-tooltip';

const MyMints = ({mints, handleShow, start}) => {

        console.log(mints)
        let language = new LocalizedStrings(
            lang,
            {
                customLanguageInterface: customInterfaceFunction
            }
            );  
        let length = mints.length <= 1 ? "col-lg-6 col-md-6 col-12 ms-5 me-3 px-0 mt-5 bordershadow text-center" : "col-lg-3 col-md-6 col-12 ms-5 me-3 px-0 mt-5 bordershadow";
        let  myMints = mints.map((p,index) => {
            start = start + 1;
            let fillForm = language.fillForm;
            let classFillform = "button add-list-button mb-3";
            let classFillform2 = "btn";
            let icon = "";
            if(p.email){
                icon = ""; 
                fillForm = language.editForm;
                classFillform = "button1 add-list-button mb-3";
                classFillform2 = "btn1"
            }
            return (
                    <div key = {index} className={length}>
                        <div className="single-blog wow fadeInUp" data-wow-delay=".2s"> 
                            <div className="blog-content text-center">
                                <h5 className="mt-4">
                                {"# "+(start)}
                                </h5>
                                <a className="mt-4 mx-4 mb-4 text-dark" target="_blank" rel="noreferrer" href={"https://testnet.bscscan.com/tx/"+p.hash}>
                                    <span className="underline">{language.seeIn + " bscscan "}<i className="lni lni-enter text-black"></i></span>
                                </a>
                                <div className={classFillform}>
                                    <button onClick={() => handleShow(p.id, "mints")} type="button" className={classFillform2}>{icon} {fillForm}</button>
                                </div>
                                <div className="mb-4"><i className="lni lni-question-circle text-dark icon-lg" data-for={""+start} data-tip={language.helpfill}>
                                </i></div>
                                <ReactTooltip id={""+start} place="top" type="info" effect="float"/>
                                                               
                            </div>
                                
                        </div>
                        

                    </div>       
            )
           
        });
        
        return(myMints)
    
}

export default MyMints