import React, { useState, useEffect, useRef } from 'react'
import MyAccount from './MyAccount'
import { ethers } from 'ethers';
import { ContractABI } from "../../contracts/Abi";
import { contractAddress } from "../../contracts/Contract";
import api_base_url from '../../components/config'
import lang from '../../components/languages/lang'
import LocalizedStrings from 'localized-strings';
import customInterfaceFunction from '../../components/languages/customInterfaceFunction';
import Swal from 'sweetalert2'
import Loading from '../../components/Loading'
import 'moment-timezone';
import 'moment/locale/es';
import moment from 'moment';

const MyAccountContainer = (props) => {
    const { ethereum } = window;

    let language = new LocalizedStrings(
        lang,
        {
            customLanguageInterface: customInterfaceFunction
        }
        ); 
    useEffect(() => {
        myNfts();
        getOwnerHandler();
    },[]);
    const [nfts, setNfts]   = useState([]);
    const [loading, setLoading] = useState(false);
    const [baseTokenURI, setBaseTokenURI]   = useState("");
	const [data, setData] = useState([])
	const [dataEntries, setDataEntries] = useState([])
	const [myMintData, setMyMintData] = useState([])
	const [myMintDataContests, setMyMintDataContests] = useState([])
	const [myMintMessage, setMyMintMessage] = useState("")
	const [noEntries, setNoEntries] = useState("")
    const [state, setState] = useState({
        mailing_address_1: "",
        mailing_address_2: "",
        mailing_name: "",
        mailing_country: "",
        mailing_state: "",
        mailing_city: "",
        zip_code: "",
        email: "",
        phone: "",
        mint_id:"",
    });
    const [stateAdmin, setStateAdmin] = useState({
        url: "",
        price: "",
        supply: "",
        max: "",
    });
    const [stateContest, setStateContest] = useState({
        beneficiary_name: "",
        beneficiary_email: "",
        multimedia_path: "",
        certificate_path: "",
        contesteable_id : "",
        course_desc: "",
        mint_id: ""
    });
    const [stateMessage, setStateMessage] = useState({
        mailing_address_1: "",
        mailing_address_2: "",
        mailing_name: "",
        mailing_country: "",
        mailing_state: "",
        mailing_city: "",
        zip_code: "",
        email: "",
        phone: "",
        mint_id:"",
    });
    const [stateMessageContest, setStateMessageContest] = useState({
        beneficiary_name: "",
        beneficiary_email: "",
        multimedia_path: "",
        certificate_path: "",
        course_desc: "",
        mint_id: "",
        contesteable_id : "",

    });
    const [show, setShow] = useState(false);
    const [showContests, setShowContests] = useState(false);
    const [showMiabc, setShowMiabc] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [mintPart, setMintPart] = useState([]);
    const [mintPartContests, setMintPartContests] = useState([]);
    const [start, setStart] = useState(0); 
    const [startContests, setStartContests] = useState(0); 
    const [certificate, setCertificate] = useState();
    const [multimedia, setMultimedia] = useState();
    const [isOwner, setIsOwner] = useState(false);

    useEffect(() => {  
        myMints();
        getStateAdmin();            
    }, [])
    useEffect(() => {
        if(showMiabc === true)
            handleClear()
     
    }, [showMiabc])
    
    const handleClose = () => {
       
        setShow(false);
        setShowMiabc(false);
    
    };
    const handleCloseContests = () => {
       
        setShowContests(false);
    
    };
    const handleStart = () => {       
        setActivePage(1);
        myMints() ;       
    };
    const tabButton = useRef(null);
    const tabButton1 = useRef(null);
    const multimediaRef = useRef("");
    const certificateRef = useRef("");
    
    const getOwnerHandler = async () => {
        try {
          if (ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            const nftContract = new ethers.Contract(contractAddress, ContractABI, signer);  
            let owner = await nftContract.owner();
            const [account] = await ethereum.request({ method: 'eth_requestAccounts' });    
            if (owner.toLowerCase() === account.toLowerCase()) {
                setIsOwner(true);
            }
          }else {
          }
        } catch (error) {
        }
    }

    const handlePageChange = (pageNumber) => {
        let arrMint = [];
        setActivePage(pageNumber);
        let start = (pageNumber-1)*6;
        setStart(start);
        let end = (start+(6-1));
        myMintData.map((m, index) => {
            if(index >= start && index <= end){
                arrMint.push(m);    
            }            
        })
        setMintPart(arrMint);
    }
    const handlePageChangeContests = (pageNumber) => {
       
        let arrMintContest = [];
        setActivePage(pageNumber);
        let start = (pageNumber-1)*1;
        setStartContests(start);
        let end = (start+(1-1));
        myMintDataContests.map((m, index) => {
            if(index >= start && index <= end){
                arrMintContest.push(m);    
            }            
        })
        setMintPartContests(arrMintContest);
        /* handleClearContest();
        console.log(stateContest); */

    }
    const handleShow = (id, modalId) => {
        setShow(modalId)
        var result = myMintData.find(obj => {
            return obj.id === id;
        })
        setState({
            ...state,
            mint_id: id,
            mailing_address_1: result.mailing_address_1,
            mailing_address_2: result.mailing_address_2,
            mailing_name: result.mailing_name,
            mailing_country: result.mailing_country,
            mailing_state: result.mailing_state,
            mailing_city: result.mailing_city,
            zip_code: result.zip_code,
            email: result.email,
            phone: result.phone,
            miabc_benefits : result.miabc_benefits,
        }); 
        setShowMiabc(result.miabc_benefits ? true : false);
    };
    const handleShowContests = (id,modalId) => {
        setShowContests(modalId);
        var result = myMintDataContests[activePage-1].contests.find(obj => {
            /* console.log(obj) */
            return obj.pivot.id === id;
        })
        setStateContest({
            ...stateContest,
            beneficiary_name: result.pivot.name,
            beneficiary_email: result.pivot.email,
            multimedia_path: result.pivot.multimedia_path,
            certificate_path: result.pivot.certificate_path,
            course_desc: result.description,
            mint_id: result.id,
            contesteable_id : result.pivot.id,
            
        });                    
    };

    const handleMiabc = () => {
        setShowMiabc(!showMiabc);        
    };
    
    const myNfts = async () => {

         //Retrieving accounts
         const accounts = await ethereum.request({ method: 'eth_accounts' });
         var arr = [];
         if (accounts.length !== 0) {
            const account = accounts[0];
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            const nftContract = new ethers.Contract(contractAddress, ContractABI, signer);  
            let nfts = await nftContract.tokensOfOwner(account);
            let baseURI = await nftContract.baseTokenURI();
            setNfts(nfts);
            setBaseTokenURI(baseURI);
            const fetchResource = async () => {
                for (let index = 0; index < nfts.length; index++) {
                        try{
                            let config = {
                                method: 'GET',
                                headers:{
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'	
                                }
                            }
                            let res = await fetch(baseURI+nfts[index], config);
                            let data = await res.json();
                            arr.push(data); 
                            
                        }
                        catch(error) {                       
                        }
        
                }
                setData(arr)
            }                
            fetchResource()
         }

    }
    const getToken = () => {
        let token = moment.utc().valueOf(); 
        /* let token = new Date(); */
        /* token = token.getUTCMinutes(); */
        /* console.log(token); */
        return token;
    }
    const myMints = async () => {
       
        //Retrieving accounts
        const accounts = await ethereum.request({ method: 'eth_accounts' });
        if (accounts.length !== 0) {
            const account = accounts[0];
            const fetchResource = async () => {          
                try{
                    let config = {
                        method: 'GET',
                        headers:{
                            'Authorization': getToken() + account,
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'	
                        }
                    }
                    
                    let res = await fetch(`${api_base_url}/customer-mints/${account}`, config)
                    let data = await res.json()
                    setMyMintData(data)
                    setMyMintDataContests(data.filter(res => res.email!==null))
                    let arrMint = [];
                    let arrMintContest = [];
                    let start = 0;
                    setStart(start);
                    setStartContests(start);
                    let end = 5;
                    let endContest = 0;
                    data.map((m, index) => {
                        if(index >= start && index <= end){
                            arrMint.push(m);        
                        }                        
                    })
                    let tempFilter = data.filter(res => res.email!==null)
                    tempFilter.map((m, index) => {
                        if(index >= start && index <= endContest){
                            arrMintContest.push(m);        
                        }
                    })
                    setMintPart(arrMint);
                    setMintPartContests(arrMintContest);
                    setActivePage(1);

                    if(res.status===404){
                          setMyMintMessage(language.myMintMessage);                          
                    }
                    

                    
                }
                catch(error) {                       
                }
          
            }                
           fetchResource();
           
        }

   }
   const handleGetEntries = async () => {      
        try{
            const accounts = await ethereum.request({ method: 'eth_accounts' });
            const account = accounts[0];    
            let config = {
                method: 'GET',
                headers:{
                    'Authorization': getToken() + account,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'	
                }
            }            
            let res = await fetch(`${api_base_url}/contest-entries`, config);
            let data = await res.json();
            setDataEntries(data);
            console.log(data);
            if(res.status===404){
                setNoEntries(language.noEntries);                          
            }    
        }
        catch(error) {                       
        }
    
    

}

   const getStateAdmin = async () => {
    
        //Retrieving accounts
        /* const accounts = await ethereum.request({ method: 'eth_accounts' }); */
        if (ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            const nftContract = new ethers.Contract(contractAddress, ContractABI, signer);  
            let supply = await nftContract.MAX_SUPPLY();
            let max = await nftContract.MAX_PER_MINT();
            let priceWei = await nftContract.PRICE();
            let priceEther = ethers.utils.formatEther(priceWei);
            let baseURI = await nftContract.baseTokenURI();
            
            setStateAdmin({
                ...stateAdmin,
                url : baseURI,
                supply : supply,
                price : priceEther,
                max : max
            })
        }



    }
   
   const handleChange = e => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setState({
      ...state,
      [name]: value
    });
  }
  const handleChangeContests = e => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setStateContest({
      ...stateContest,
      [name]: value
    });
  }
  const handleChangeAdmin = e => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setStateAdmin({
      ...stateAdmin,
      [name]: value
    });
  }
  const onSelectFile = () => {
    if (certificateRef.current.files && certificateRef.current.files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () =>{
            if(certificateRef.current.files[0].size <= 2000000) {         
                setCertificate(reader.result)
            }else{
                certificateRef.current.value = "";
                setMultimedia(null)     
                Swal.fire({
                    title: "Error",
                    text: language.sizeError,
                    icon: "error",
                    showConfirmButton: true,
                    confirmButtonColor: '#ED4A3A',
                    }
                )
            }
        });
        reader.readAsDataURL(certificateRef.current.files[0]);
    }
  };
  const onSelectFileMultimedia = () => {
    if (multimediaRef.current.files && multimediaRef.current.files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () =>{  
            if(multimediaRef.current.files[0].type === "video/quicktime" || multimediaRef.current.files[0].type === "video/avi" || multimediaRef.current.files[0].type === "video/mp4" || multimediaRef.current.files[0].type === "video/m4v" || multimediaRef.current.files[0].type === "video/mpg"){
                if(multimediaRef.current.files[0].size <= 10000000) {         
                    setMultimedia(reader.result) 
                }else{
                    multimediaRef.current.value = "";
                    setMultimedia(null)     
                    Swal.fire({
                        title: "Error",
                        text: language.sizeError2,
                        icon: "error",
                        showConfirmButton: true,
                        confirmButtonColor: '#ED4A3A',
                        }
                    )
                                   
                } 
            }else{
                if(multimediaRef.current.files[0].size <= 2000000) {         
                    setMultimedia(reader.result)
                }else{
                    multimediaRef.current.value = "";
                    setMultimedia(null)     
                    Swal.fire({
                        title: "Error",
                        text: language.sizeError,
                        icon: "error",
                        showConfirmButton: true,
                        confirmButtonColor: '#ED4A3A',
                        }
                    )
                                   
                }  
            }
                
        });
        reader.readAsDataURL(multimediaRef.current.files[0]);
    }
  };
  const handleClear = async () => {
    setState({
      ...state,
      mailing_address_1 : "",
      mailing_address_2 : "",
      mailing_country : "",
      mailing_state : "",
      zip_code : "",
      phone: "",
    });
  }
  /* const handleClearContest = async () => {
    setState({
      ...stateContest,
        beneficiary_name: "",
        beneficiary_email: "",
        multimedia_path: "",
        certificate_path: "",
        course_desc: "",
        contesteable_id : "",
    });
  } */
  const handleChangeCountry = e => {
    const value = e;
    setState({
      ...state,
      mailing_country : value
    });
  }
  const handleChangeRegion = async e => {
    const value = e;
    setState({
      ...state,
      mailing_state : value
    });
  }
    const handleSubmit = async e => {
        setLoading(true);
        e.preventDefault();
        const accounts = await ethereum.request({ method: 'eth_accounts' });
        const account = accounts[0];
        handleClose();      
        try{
            if(showMiabc === true){
            }
            
            let config = {
            method: 'PUT',
            headers:{
            'Authorization': getToken() + account,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({mailing_address_1: state.mailing_address_1, mailing_address_2: state.mailing_address_2, mailing_name: state.mailing_name, mailing_country: state.mailing_country, mailing_state: state.mailing_state, mailing_city: state.mailing_city, zip_code: state.zip_code, email:  state.email, phone: state.phone, miabc_benefits: showMiabc})
        }
        let res = await fetch(`${api_base_url}/mints/${state.mint_id}`, config);
        let json = await res.json();
        var icon = '';
        if(json.error){ 
            Swal.fire({
                title: "Error",
                text: language.errorMessage,
                icon: "error",
                showConfirmButton: true,
                confirmButtonColor: '#ED4A3A',
                })                         
        }else{
            icon = "success"
            Swal.fire({
            title: language.done,
            text: language.doneMessage,
            icon: icon,
            showConfirmButton: true,
            confirmButtonColor: '#ED4A3A',
            timer : 15000,
            }).then(function(){ 
                window.location.reload();
            })  

        }
        setLoading(false);
        

        }catch (error){
        setLoading(false);     
        
        }
    }
    const handleSubmitContests = async e => {
        setLoading(true);
        e.preventDefault();
        const accounts = await ethereum.request({ method: 'eth_accounts' });
        const account = accounts[0];      
        handleCloseContests();      
        try{            
            let config = {
            method: 'PUT',
            headers:{
                'Authorization': getToken() + account,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({name: stateContest.beneficiary_name, email: stateContest.beneficiary_email, multimedia_path: multimedia, certificate_path: certificate})
        }
        let res = await fetch(`${api_base_url}/contests/${stateContest.contesteable_id}`, config);
        let json = await res.json(); 
        let icon = '';
        if(json.error1){ 
            Swal.fire({
                title: "Error",
                text: language.errorMessage1,
                icon: "error",
                showConfirmButton: true,
                confirmButtonColor: '#ED4A3A',
                timer : 15000,
            }).then(function(){ 
                window.location.reload();
            });  
        }
        else if(json.error){ 
            setStateMessageContest({
            ...stateMessageContest,
            name : json.error ? (json.error.name ? language.name : '') : '',
            email: json.error ? (json.error.email ? language.emailPart : '') : '',
            multimedia_path: json.error ? (json.error.multimedia_path ? language.multimedia : '') : '',
            certificate_path: json.error ? (json.error.certificate_path ? language.certificate : '') : '',
            });      
            Swal.fire({
                title: "Error",
                text: language.errorMessage,
                icon: "error",
                showConfirmButton: true,
                confirmButtonColor: '#ED4A3A',
                timer : 15000,
            }).then(function(){ 
                window.location.reload();
            });                        
        }else{
            icon = "success";
            Swal.fire({
                title: language.done,
                text: language.doneMessage,
                icon: icon,
                showConfirmButton: true,
                confirmButtonColor: '#ED4A3A',
                timer : 15000,
            }).then(function(){ 
                window.location.reload();
            });
        }
        
        setLoading(false);
        

        }catch (error){
        setLoading(false);     
        
        }
    }
    
    const handleSubmitAdmin = async e => {
        setLoading(true);
        e.preventDefault();
        try{
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            const nftContract = new ethers.Contract(contractAddress, ContractABI, signer);  
            
            let baseURI = await nftContract.baseTokenURI();
            let supplyBig = await nftContract.MAX_SUPPLY();
            let supply = ethers.utils.formatEther(supplyBig);
            let maxBig = await nftContract.MAX_PER_MINT();
            let max = ethers.utils.formatEther(maxBig);
            let priceWei = await nftContract.PRICE();
            let priceEther = ethers.utils.formatEther(priceWei);
            
            
            if(baseURI !== stateAdmin.url){
                const txnUrl = await nftContract.setBaseURI(stateAdmin.url);
                await txnUrl.wait();  
            }
            if(supply !== ethers.utils.formatEther(stateAdmin.supply)){
                const txnSupply = await nftContract.updateSupply(stateAdmin.supply);
                await txnSupply.wait();  
            }
            if(max !== ethers.utils.formatEther(stateAdmin.max)){
                const txnMax = await nftContract.updateMaxPerMint(stateAdmin.max);
                await txnMax.wait();  
            }
            if(priceEther !== stateAdmin.price){
                const txnPrice = await nftContract.updatePrice(ethers.utils.parseEther(stateAdmin.price));
                await txnPrice.wait();  
            }

            let icon = "success"
            Swal.fire({
            title: language.done,
            text: language.doneMessage,
            icon: icon,
            showConfirmButton: true,
            confirmButtonColor: '#ED4A3A',
            timer : 15000,
            }).then(function(){ 
                window.location.reload();
            })  

        setLoading(false);
        }catch (error){
            setLoading(false);     
        }

    }

    if(loading)
    return <Loading/>
    
    return <MyAccount myAccountMenu = {props.myAccountMenu}
                    nfts = {data}
                    baseTokenURI = {baseTokenURI}
                    myMintMessage = {myMintMessage}
                    mints = {myMintData}
                    mintsContests = {myMintDataContests}
                    handleShow = {handleShow}
                    handleClose = {handleClose}
                    show = {show}
                    handleSubmit = {handleSubmit}
                    handleSubmitContests = {handleSubmitContests}
                    handleChange = {handleChange}
                    handleChangeContests = {handleChangeContests}
                    handleChangeRegion = {handleChangeRegion}
                    handleChangeCountry = {handleChangeCountry}
                    handleMiabc = {handleMiabc}
                    state = {state}
                    stateContest = {stateContest}
                    showMiabc = {showMiabc}
                    stateMessage={stateMessage}
                    handlePageChange = {handlePageChange}
                    handlePageChangeContests = {handlePageChangeContests}
                    activePage = {activePage}
                    mintPart = {mintPart}
                    mintPartContests = {mintPartContests}
                    start = {start}
                    startContests = {startContests}
                    handleStart = {handleStart}
                    tabButton = {tabButton}
                    tabButton1 = {tabButton1}
                    multimediaRef = {multimediaRef}
                    certificateRef = {certificateRef}
                    handleShowContests ={handleShowContests}
                    handleCloseContests = {handleCloseContests}
                    showContests = {showContests}
                    onSelectFile = {onSelectFile}
                    onSelectFileMultimedia = {onSelectFileMultimedia}
                    isOwner = {isOwner}
                    handleSubmitAdmin = {handleSubmitAdmin}
                    handleChangeAdmin = {handleChangeAdmin}
                    stateAdmin = {stateAdmin}
                    handleGetEntries={handleGetEntries}
                    entries = {dataEntries}
                    noEntries = {noEntries}

            />

}

export default MyAccountContainer