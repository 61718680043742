import React from 'react'
import Menu from '../Menu/MenuContainer'
import Footer from '../Footer/FooterContainer'
import {Link} from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';
import lang from '../../components/languages/lang'
import LocalizedStrings from 'localized-strings';
import customInterfaceFunction from '../../components/languages/customInterfaceFunction';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import base_url_assets from '../../base_url_assets';

const Home = ({mintNftHandler, handleShow, handleClose, show, handleSubmit, handleChange, handleChangeRegion, handleChangeCountry, state, message, myAccountMenu}) => {
    
    let language = new LocalizedStrings(
        lang,
        {
            customLanguageInterface: customInterfaceFunction
        }
        ); 

    return(
        <>  <Menu myAccountMenu={myAccountMenu}/>
            <section id="home" className="hero-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-12 col-12">
                            <div className="hero-content">
                                <h1 className="wow fadeInLeft" data-wow-delay=".4s">{language.title}</h1>
                                <p className="wow fadeInLeft" data-wow-delay=".6s">{language.subtitle}</p>
                                <div className="button wow fadeInLeft" data-wow-delay=".8s">
                                    <HashLink to="#pricing" className="btn"><i className="lni lni-investment"></i>{language.getNft}</HashLink>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12">
                            <div className="hero-image wow fadeInRight" data-wow-delay=".4s">
                                <img src={base_url_assets+"/assets/images/others/phone1.png"} alt="#"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section id="features" className="features section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title">
                                {/* <h3 className="wow zoomIn" data-wow-delay=".2s">Features</h3> */}
                                <h2 className="wow fadeInUp" data-wow-delay=".4s">{language.featureTitle}
                                </h2>
                                <p className="wow fadeInUp" data-wow-delay=".6s">{language.featureTitle2}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12">
                        
                            <div className="single-feature wow fadeInUp" data-wow-delay=".2s">
                                <i className="lni lni-layout"></i>
                                <h3>{language.feature1Title}</h3>
                                <p>{language.feature1}</p>
                            </div>
                        
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                        
                            <div className="single-feature wow fadeInUp" data-wow-delay=".4s">
                                <i className="lni lni-gallery"></i>
                                <h3>{language.feature2Title}</h3>
                                <p>{language.feature2}</p>
                            </div>
                        
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                        
                            <div className="single-feature wow fadeInUp" data-wow-delay=".6s">
                                <i className="lni lni-pencil"></i>
                                <h3>{language.feature3Title}</h3>
                                <p>{language.feature3}</p>
                            </div>
                        
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                        
                            <div className="single-feature wow fadeInUp" data-wow-delay=".2s">
                                <i className="lni lni-bookmark"></i>
                                <h3>{language.feature4Title}</h3>
                                <p>{language.feature4}</p>
                            </div>
                        
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                        
                            <div className="single-feature wow fadeInUp" data-wow-delay=".4s">
                                <i className="lni lni-home"></i>
                                <h3>{language.feature5Title}</h3>
                                <p>{language.feature5}</p>
                            </div>
                        
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                        
                            <div className="single-feature wow fadeInUp" data-wow-delay=".6s">
                                <i className="lni lni-skipping-rope"></i>
                                <h3>{language.feature6Title}</h3>
                                <p>{language.feature6}</p>
                            </div>
                        
                        </div>
                    </div>
                    <section  className=" blog-section section"> 
                        <div className="row mt-100">
                            <div className="col-12">
                                <div className="section-title">
                                    <h2 className="wow fadeInUp" data-wow-delay=".4s">{language.featureCourses}</h2>
                                    <p className="wow fadeInUp" data-wow-delay=".6s">{language.featureCoursesDesc}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-10">
                            <div className="col-lg-3 col-md-6 col-12">

                                <div className="single-blog wow fadeInUp" data-wow-delay=".2s">
                                    <div className="blog-img">
                                        <img src={base_url_assets+"/assets/images/others/impactantes.jpeg"} alt="#"/>
                                    </div>
                                    <div className="blog-content">
                                        <h5>
                                        {language.course1}
                                        </h5>
                                        <p>{language.course1Desc}</p>
                                        {/* <div className="autor">
                                            <a href="javascript:void(0)"><img src={base_url_assets+"/assets/images/others/john-avatar.jpg" alt="#"/></a>
                                            <a href="javascript:void(0)" className="name pt-2">John Cortéz</a>
                                            <ul className="meta-content">
                                            <li>
                                            <a href="javascript:void(0)">Mar 15,2023</a>
                                            </li>
                                            <li>
                                            <a href="javascript:void(0)">5 min read</a>
                                            </li>
                                            </ul>
                                        </div> */}
                                    
                                    </div>
                                    <div className="button text-center mb-4">
                                        <HashLink to="#pricing" className="btn">{language.getBox}</HashLink>
                                    </div>

                                </div>

                            </div>
                            <div className="col-lg-3 col-md-6 col-12">

                                <div className="single-blog wow fadeInUp" data-wow-delay=".4s">
                                <div className="blog-img">
                                <img src={base_url_assets+"/assets/images/others/falsa.png"} alt="#"/>
                                </div>
                                <div className="blog-content">
                               
                                <h5>
                                    {language.course2}
                                </h5>
                                <p>{language.course2Desc}</p>
                                {/* <div className="autor">
                                <a href="javascript:void(0)"><img src={base_url_assets+"/assets/images/others/john-avatar.jpg"} alt="#"/></a>
                                <a href="javascript:void(0)" className="name">Jenifer Zuliya</a>
                                <ul className="meta-content">
                                <li>
                                <a href="javascript:void(0)">Feb 10,2023</a>
                                </li>
                                <li>
                                <a href="javascript:void(0)">7 min read</a>
                                </li>
                                </ul>
                                </div> */}
                                 
                                </div>
                                <div className="button text-center mb-4">
                                    <HashLink to="#pricing" className="btn">{language.getBox}</HashLink>
                                </div>
                                </div>
                                

                            </div>
                            <div className="col-lg-3 col-md-6 col-12">

                                <div className="single-blog wow fadeInUp" data-wow-delay=".6s">
                                    <div className="blog-img">
                                        <img src={base_url_assets+"/assets/images/others/kpop.png"} alt="#"/>
                                    </div>
                                    <div className="blog-content">
                                        <h5>
                                        {language.course3}
                                        </h5>
                                        <p>{language.course3Desc}</p>
                                        {/* <div className="autor">
                                            <a href="javascript:void(0)"><img src={base_url_assets+"/assets/images/others/john-avatar.jpg" alt="#"/></a>
                                            <a href="javascript:void(0)" className="name">Roel Aufderhar</a>
                                            <ul className="meta-content">
                                            <li>
                                            <a href="javascript:void(0)">Jan 20,2023</a>
                                            </li>
                                            <li>
                                            <a href="javascript:void(0)">6 min read</a>
                                            </li>
                                            </ul>
                                        </div> */}
                                        
                                    </div>
                                    <div className="button text-center mb-4">
                                    <HashLink to="#pricing" className="btn">{language.getBox}</HashLink>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-3 col-md-6 col-12">

                                <div className="single-blog wow fadeInUp" data-wow-delay=".6s">
                                    <div className="blog-img">
                                       
                                        <img src={base_url_assets+"/assets/images/others/tequenos.png"} alt="#"/>
                                        
                                    </div>
                                    <div className="blog-content">
                                        <h5>
                                        {language.course4}
                                        </h5>
                                        <p>{language.course4Desc}</p>
                                        {/* <div className="autor">
                                            <a href="javascript:void(0)"><img src={base_url_assets+"/assets/images/others/john-avatar.jpg" alt="#"/></a>
                                            <a href="javascript:void(0)" className="name">Roel Aufderhar</a>
                                            <ul className="meta-content">
                                            <li>
                                            <a href="javascript:void(0)">Jan 20,2023</a>
                                            </li>
                                            <li>
                                            <a href="javascript:void(0)">6 min read</a>
                                            </li>
                                            </ul>
                                        </div> */}
                                        
                                    </div>
                                    <div className="button text-center mb-4">
                                    <HashLink to="#pricing" className="btn">{language.getBox}</HashLink>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section> 
                </div>
                
            </section>        
                
               
            
            
            {/* <section className="our-achievement section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 col-md-12 col-12">
                            <div className="title">
                                <h2>Trusted by developers from over 80 planets</h2>
                                <p>There are many variations of passages of Lorem Ipsum available, but the majority.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 col-md-12 col-12">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-12">
                                    <div className="single-achievement wow fadeInUp" data-wow-delay=".2s">
                                        <h3 className="counter"><span id="secondo1" className="countup" cup-end="100">100</span>%</h3>
                                        <p>satisfaction</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-12">
                                    <div className="single-achievement wow fadeInUp" data-wow-delay=".4s">
                                        <h3 className="counter"><span id="secondo2" className="countup" cup-end="120">120</span>K</h3>
                                        <p>Happy Users</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-12">
                                    <div className="single-achievement wow fadeInUp" data-wow-delay=".6s">
                                        <h3 className="counter"><span id="secondo3" className="countup" cup-end="125">125</span>k+</h3>
                                        <p>Downloads</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* Modal info */}
                {/* <Button variant="primary" onClick={handleShow}>
                    Launch demo modal
                </Button> */}
                <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Datos del minteo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>{language.email}</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Password" />
                                </Form.Group>
                            </Row>

                            <Form.Group className="mb-3" controlId="formGridAddress1">
                                <Form.Label>{language.address}</Form.Label>
                                <Form.Control placeholder="1234 Main St" />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formGridAddress2">
                                <Form.Label>{language.address2}</Form.Label>
                                <Form.Control placeholder="Apartment, studio, or floor" />
                            </Form.Group>

                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridCity">
                                <Form.Label>{language.mailingCountry}</Form.Label>
                                <CountryDropdown value={state.mailing_country} onChange={handleChangeCountry} className="form-control"/>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState">
                                <Form.Label>{language.mailingCity}</Form.Label>
                                <RegionDropdown disableWhenEmpty={true} country={state.mailing_country} value={state.mailing_state} onChange={handleChangeRegion} className="form-control"/>
                                {/* <Form.Select defaultValue="Choose...">
                                    <option>Choose...</option>
                                    <option>...</option>
                                </Form.Select> */}
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridZip">
                                <Form.Label>{language.mailingZip}</Form.Label>
                                <Form.Control/>
                                </Form.Group>
                            </Row>

                            <Form.Group className="mb-3" id="formGridCheckbox">
                                <Form.Check type="checkbox" label="Check me out" />
                            </Form.Group>

                            <Button variant="primary" type="button" onClick={handleSubmit}>
                                {language.mailingSubmit}
                            </Button>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {language.mailingClose}
                    </Button>
                    {/* <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button> */}
                    </Modal.Footer>
                </Modal>
            {/* Modal info */}

            <section id="pricing" className="pricing-table section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title">
                                <h2 className="wow fadeInUp" data-wow-delay=".4s">{language.packs} </h2>
                                <p className="wow fadeInUp" data-wow-delay=".6s">{language.packsSub}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="offset-lg-1 col-lg-4 col-md-6 col-12">
                        
                            <div className="single-table wow fadeInUp" data-wow-delay=".2s">
                            
                                <div className="table-head">
                                    <h4 className="title">{language.cartoon}</h4>
                                    <p>{language.cartoonSub}</p>
                                    <div className="price">
                                        <h2 className="amount">0.5 BNB<span className=""><img className="mb-1" src = {base_url_assets+"/assets/images/logo/bnb3.png"} alt="#" width={59}></img></span></h2>
                                    </div>
                                    <div className="button">
                                        <Link to="#" className="btn" onClick={mintNftHandler}>{language.buyCartoon}</Link>
                                    </div>
                                </div>
                            
                            
                                <div className="table-content">
                                    <h4 className="middle-title">{language.included}</h4>
                                
                                    <ul className="table-list">
                                        <li><i className="lni lni-checkmark-circle"></i> {language.basic5}</li>
                                        <li><i className="lni lni-checkmark-circle"></i> {language.basic1}</li>
                                        <li><i className="lni lni-checkmark-circle"></i> {language.basic2}</li>
                                        <li><i className="lni lni-checkmark-circle"></i> {language.basic3}</li>
                                        <li><i className="lni lni-checkmark-circle"></i> {language.basic4}</li>
                                        <li><i className="lni lni-checkmark-circle"></i> {language.basic6}</li>
                                        <li><i className="lni lni-checkmark-circle"></i> {language.basic7}</li>
                                        <li><i className="lni lni-checkmark-circle"></i> {language.basic8}</li>
                                        <li><i className="lni lni-checkmark-circle"></i> {language.basic9}</li>
                                        
                                    </ul>
                                
                                </div>
                                
                            </div>
                        
                        </div>
                        {/* <div className="col-lg-3 col-md-6 col-12">
                        
                            <div className="single-table wow fadeInUp" data-wow-delay=".4s">
                            
                                <div className="table-head">
                                    <h4 className="title">{language.pixelArt}</h4>
                                    <p>{language.pixelArtSub}</p>
                                    <div className="price">
                                        <h2 className="amount">0.5 BNB<span className=""><img className="mb-1" src = {base_url_assets+"/assets/images/logo/bnb3.png"} alt="#" width={58}></img></span></h2>
                                    </div>
                                    <div className="button">
                                        <Link to="#" className="btn">{language.buyPixelArt}</Link>
                                    </div>
                                </div>
                            
                                <div className="table-content">
                                    <h4 className="middle-title">{language.included}</h4>
                                
                                    <ul className="table-list">
                                        <li><i className="lni lni-checkmark-circle"></i> {language.premium5}</li>
                                        <li><i className="lni lni-checkmark-circle"></i> {language.premium1}</li>
                                        <li><i className="lni lni-checkmark-circle"></i> {language.premium2}</li>
                                        <li><i className="lni lni-checkmark-circle"></i> {language.premium3}</li>
                                        <li><i className="lni lni-checkmark-circle"></i> {language.premium4}</li>
                                        <li><i className="lni lni-checkmark-circle"></i> {language.premium6}</li>


                                    </ul>
                                
                                </div>
                            
                            </div>
                            
                        </div> */}
                        {/* <div className="col-lg-3 col-md-6 col-12">
                        
                            <div className="single-table wow fadeInUp" data-wow-delay=".6s">
                            
                                <div className="table-head">
                                    <h4 className="title">{language.familyCards}</h4>
                                    <p>{language.customArtSub}</p>
                                    <div className="price pt-2">
                                        <h2 className="amount">0.1<span className="duration"><img className="mb-2 ms-1" src = "/assets/images/logo/bnb.png" alt="#" width={24}></img></span></h2>
                                    </div>
                                    <div className="button mt-10">
                                        <Link to="#" className="btn disabled-link">{language.buyCustom}</Link>
                                    </div>
                                </div>
                            
                                <div className="table-content">
                                    <h4 className="middle-title">{language.included}</h4>
                                
                                    <ul className="table-list">
                                        <li><i className="lni lni-checkmark-circle"></i>{language.custom1}</li>
                                        
                                    </ul>
                                
                                </div>
                            
                            </div>
                        
                        </div> */}
                        <div className="offset-lg-2 col-lg-4 col-md-6 col-12">
                        
                            <div className="single-table wow fadeInUp" data-wow-delay=".8s">
                            
                                <div className="table-head">
                                    <h4 className="title">{language.customCards}</h4>
                                    <p>{language.customArtSub}</p>
                                    <div className="price">
                                        <h2 className="amount">N/A BNB<span className=""><img className="mb-1" src = {base_url_assets+"/assets/images/logo/bnb3.png"} alt="#" width={59}></img></span></h2>
                                    </div>
                                    <div className="button">
                                        <Link to="#" className="btn disabled-link">{language.buyCustom}</Link>
                                    </div>
                                </div>
                            
                                <div className="table-content">
                                    <h4 className="middle-title">{language.included}</h4>
                                
                                    <ul className="table-list">
                                        <li><i className="lni lni-checkmark-circle"></i>{language.custom1}</li>
                                    </ul>
                                
                                </div>
                            
                            </div>
                        
                        </div>
                    </div>
                </div>
            </section>

            <section id="team" className="team section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title">
                            <h2 className="wow fadeInUp" data-wow-delay=".4s">{language.meet}</h2>
                            <p className="wow fadeInUp" data-wow-delay=".6s">{language.meetSub}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12">

                            <div className="single-team wow fadeInUp" data-wow-delay=".2s">
                                <div className="image row">
                                    <img /* className="bottom" */ src={base_url_assets+"/assets/images/others/eli.png"} alt="#"/>
                                    {/* <img className="top" src={base_url_assets+"/assets/images/others/team2.jpg" alt="#"/> */}
                                </div>
                                <div className="content">
                                    <div className="row align-items-center">
                                        <div className="col-lg-7 col-12">
                                            <div className="text">
                                                <h3><a>Elizabeth Diaz</a></h3>
                                                <h5>CEO</h5>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-12">
                                            <ul className="social">
                                                <li><a href="https://www.linkedin.com/in/elizabeth-diaz-6241a715" rel="noreferrer" target="_blank"><i className="lni lni-linkedin-original"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                        <div className="col-lg-4 col-md-6 col-12">

                            <div className="single-team wow fadeInUp" data-wow-delay=".6s">
                                <div className="image row">
                                    <img src={base_url_assets+"/assets/images/others/lulu.png"} alt="#"/>
                                </div>
                                <div className="content">
                                    <div className="row align-items-center">
                                        <div className="col-lg-7 col-12">
                                            <div className="text">
                                            <h3><a>Kyss Chirinos</a></h3>
                                            <h5>Designer - Community Manager</h5>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-12">
                                            <ul className="social">
                                                <li><a href="https://ve.linkedin.com/in/kysdel-chirinos-9416b0101" rel="noreferrer" target="_blank"><i className="lni lni-linkedin-original"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-4 col-md-6 col-12">

                            <div className="single-team wow fadeInUp" data-wow-delay=".4s">
                            <div className="image row">
                                <img className="bottom" src={base_url_assets+"/assets/images/others/joe.png"} alt="#"/>
                            </div>
                            <div className="content">
                            <div className="row align-items-center">
                            <div className="col-lg-7 col-12">
                            <div className="text">
                            <h3><a>Joe Rodríguez</a></h3>
                            <h5>COO - Developer</h5>
                            </div>
                            </div>
                            <div className="col-lg-5 col-12">
                                <ul className="social">
                                    <li><a href="https://www.linkedin.com/in/joe-rodriguez-86a70a87" rel="noreferrer" target="_blank"><i className="lni lni-linkedin-original"></i></a>
                                    </li>
                                </ul>
                            </div>
                            </div>
                            </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>    
            <section className="section call-action">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 col-md-12 col-12">
                            <div className="cta-content">
                                <h2 className="wow fadeInUp" data-wow-delay=".2s">{language.downloadMiabc}<br/>
                                </h2>
                                <p className="wow fadeInUp" data-wow-delay=".4s">{language.downloadSub}</p>
                                <div className="button wow fadeInUp" data-wow-delay=".6s">
                                    <HashLink to="#" className="btn"><i className="lni lni-apple"></i> App Store</HashLink>
                                    <a href="https://play.google.com/store/apps/details?id=com.diazapps.miabc" target="_blank" rel="noreferrer" className="btn btn-alt"><i className="lni lni-play-store"></i> Google
                                        Play</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
            <Footer myAccountMenu={myAccountMenu}/>       
    </>
   
    )

}

export default Home